import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { getTrendingCollections, getCollectionsLeaderboard, getAllCollections } from '../api/FileManagementApi';
import '../css/marketPlace.css';
import { MarketPlaceImage } from './imageComponents/MarketPlaceImage';
import {
    useFocusable,
    FocusContext,
  } from "@noriginmedia/norigin-spatial-navigation";
  import { useNavigate } from 'react-router-dom';
import translations from './translations';
var trendingContinuation = '';
var offsetLeaderboard = 0;
var continuation = '';

function passTest(item) {
    return (item.meta && item.meta.content && item.meta.content.length > 0 && item.meta.content[0].url)
}


/**
 * This component returns below components.
 * @returns Two Collection Rows
 */
export function MarketPlace() {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];
    useEffect(()=>{
        localStorage.setItem("shouldGoBack",true);
        localStorage.setItem("isDashboard",false);
        console.log("isDashboard",localStorage.getItem("isDashboard"));
        console.log("shouldGoBack",localStorage.getItem("shouldGoBack"));
    return () => {
      localStorage.setItem("shouldGoBack",true);
    }
    },[])
    console.log("LLLL",navigator.onLine);
    if(!navigator.onLine){

        return(
          <Container style={{"textAlign":"center","marginTop":"20%","color":"wheat","fontSize":"x-large"}}>
            
              <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>
            
          </Container>
         );
       }
       else {
        return (
            <RowsWrapper/>
        )
       }

}

function RowsWrapper() {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];

    const { ref, focusKey, setFocus  } = useFocusable({
        focusKey: 'marketplace',
      });

      useEffect(() => {
        return () => {
            setFocus('My-NFTS');
        }
      },[])

    return (
        <FocusContext.Provider value={focusKey}>
            <Row className="mt-xxl-4 mt-xl-4 mt-sm-3">
                <Container fluid id="M-container" ref={ref}>
                    <CollectionRow rowNumber={0} containerRef={ref} collectionName = "Trending Collections" collectionNameTrans = {translatedStrings?.MAIN_NFT_TRENDING_COLLECTIONS}/>
                    <CollectionRow rowNumber={2} containerRef={ref} collectionName = "All Collections" collectionNameTrans = {translatedStrings?.MAIN_NFT_ALL_COLLECTIONS}/>
                </Container>
            </Row>
            </FocusContext.Provider>
    )
}

/**
 * First when the state = 0 the component is in loading state. A spinner is shown.
 * Then a useEffect callback runs to get collections from the cache if cached else makes an api request to get collections.Also 
 * the windowState is taken from the cache if cached else is set to 0.
 * This component can return a max of 5 CollectionCard Components. The no of CollectionCard components to be returned is 
 * calculated based on the number of collections there in collections ref. 
 * @param {*} props 
 * @returns CollectionCard Components.
 */
function CollectionRow(props) {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];
    const { ref, focusKey } = useFocusable();
    const [state,setState] = useState(0);
    const collections = useRef([]);
    const [windowState,setWindowState] = useState(0);
    let cardsCount = [0,1,2,3,4];
    let defaultCardsCount = [0,1,2,3,4];
    //check the no of collections present in collections variable
    let length = collections.current.length;
    if(length < 5) { //make the cardsCount array size = length
        for(let i=1; i<= 5-length; i++) {
            cardsCount.pop();
        }
    }

    useEffect(() => {
        getCollections(props.collectionName).then((object)=>{
            collections.current = object.collections;
            setWindowState(object.windowstate);
            setState(state + 1);// Not Loading  
        }).catch((error)=> {
            if(error.code == "ERR_NETWORK"){
                setState("Network lost");
            }else{
                try {
                    window.JavaFun.nftErrorForMarketplace("rarible_server", 0);
                  }
                  catch(error) {
                    console.log(error);
                  }
                setState("Server Error");
            }
            console.log("error",error);
            // Network error
        }); 
    }, [])
    if(state == "Server Error") {
        return (
            <FocusContext.Provider value={focusKey}>
            <Container style={{"textAlign":"center","marginTop":"9%","color":"wheat","fontSize":"x-large"}}>

            <span>{translatedStrings?.MAIN_MSG_NFT_SERVER_ERROR}</span>

            </Container> 
            </FocusContext.Provider>
        );
    }else if(state == "Network lost"){
        return (
            <FocusContext.Provider value={focusKey}>
            <Container style={{"textAlign":"center","marginTop":"9%","color":"wheat","fontSize":"x-large"}}>

            <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>

            </Container> 
            </FocusContext.Provider>
        );
    }
    if(state == 0) {
        return (
            <FocusContext.Provider value={focusKey}>
            <Row style={{padding:"1vw"}}>
                <Col className="M-title mt-xxl-4 mt-xl-4 mt-sm-2">{props.collectionNameTrans}</Col>
            </Row>
            <Row >
                <div className="M-wrapper" ref={ref}>
                {defaultCardsCount.map((card)=>{
                    return (
                        <LoaderCards/>
                    )
                })}
                </div>
                </Row>
               </FocusContext.Provider> 
        );
    }
    return (
        <FocusContext.Provider value={focusKey}>
        <Row style={{padding:"1vw"}}>
            <Col className="M-title mt-xxl-4 mt-xl-4 mt-sm-2">{props.collectionNameTrans}</Col>
        </Row>
        <Row >
            <div className="M-wrapper" ref={ref}>
                {
                    cardsCount.map((index) => {
                        return(
                            <CollectionCard
                            collectionName = {props.collectionName}
                        cardsCount = {cardsCount.length}
                        item={collections.current[windowState + index]} index={index}
                          rowRef={ref} containerRef={props.containerRef}
                          collections={collections} rowState={state} setRowState = {setState}
                          windowState = {windowState} setWindowState = {setWindowState}
                          rowNumber={props.rowNumber}/>
                        )
                    })
                }
            </div>
        </Row>
        </FocusContext.Provider>
    )
}

function CollectionCard(props) {
    useEffect(() => {//logic to retrieve focus position
        if(props.collectionName == JSON.parse(localStorage.getItem("windowStateIndexType"))) {
            let cachedWindowStateIndex = JSON.parse(localStorage.getItem("windowStateIndex"));
            if(cachedWindowStateIndex != 'null' && cachedWindowStateIndex != null && cachedWindowStateIndex == props.index) {
            focusSelf();
        }
        }
    },[])
    let navigate = useNavigate();
    const apiCallInProgress = useRef(false);
    const { ref, focused,focusSelf } = useFocusable({
        onFocus:() => {//logic to align the collections properly in the viewport.
        let cardLeftDistance = props.rowRef.current.childNodes[props.index].getBoundingClientRect().left;
        let cardTopDistance = props.rowRef.current.childNodes[props.index].getBoundingClientRect().top;
                        //horizontal movement
                        if((cardLeftDistance > ((50/100)*window.innerWidth)) || (cardLeftDistance < ((30/100)*window.innerWidth))) {
                            props.rowRef.current.childNodes[props.index].scrollIntoView({block: 'nearest',inline: 'center'});
                        }
                        //vertical movement
                        if((props.containerRef.current.getBoundingClientRect().top > cardTopDistance) || (cardTopDistance > window.innerHeight - ((30/100)*window.innerHeight))) {
                            props.rowRef.current.childNodes[props.index].scrollIntoView({block: 'center',inline: 'center'});
                        }
                        setWindowStateIndex(props.index,props.collectionName);
    },
        onArrowPress:(event) => {
        let cardLeftDistance = props.rowRef.current.childNodes[props.index].getBoundingClientRect().left;
        let cardTopDistance = props.rowRef.current.childNodes[props.index].getBoundingClientRect().top;
        //continuation and move logic     
        //check if focus is on last div of cardsCount array and right button is pressed.   
        if((event == "right") && (props.index == props.cardsCount - 1)) {
            //check if last div of cardsCount is displaying the last collection in collections variable. If thats the case then an api call should be made.
            if(!((props.collections.current.length - 1) == (props.windowState + props.cardsCount - 1))){//no of collections in collections variable == nth collection the last div of cardsCount array is displaying.Where (props.cardsCount - 1) = index of the last div of cardsCount. 
                windowState(props.windowState+1,props.collectionName);
                props.setWindowState(props.windowState + 1);
            }
            else if(!apiCallInProgress.current){
                apiCallInProgress.current = true;
                (async function () {
                    try {
                        let res;
                        switch(props.rowNumber) {
                            case 0:
                                res = await getTrendingCollections({continuation: trendingContinuation});
                                trendingContinuation = res.continuation;
                                let filteredTrendingRes = await res.items.filter(passTest);
                                props.collections.current = [...props.collections.current,...filteredTrendingRes];
                                localStorage.setItem("Collectionstrending", JSON.stringify(props.collections.current)); 
                                localStorage.setItem("trendingContinuation", JSON.stringify(trendingContinuation)); 
                                apiCallInProgress.current = false;
                                props.setRowState(props.rowState+1);
                            break;
                            case 1:
                                 res = await getCollectionsLeaderboard({offset:offsetLeaderboard});
                                 offsetLeaderboard += 10;
                                 let filteredRes = res.filter(passTest);
                                 props.collections.current = [...props.collections.current,...filteredRes];
                                 localStorage.setItem("collectionLeaderBoard",JSON.stringify(props.collections.current));
                                 localStorage.setItem("offsetLeaderboard",JSON.stringify(offsetLeaderboard));
                                 apiCallInProgress.current = false;
                                 props.setRowState(props.rowState+1);
                            break;   
                            case 2:
                                 res = await getAllCollections({continuation: continuation});
                                 continuation = res.continuation;
                                 let filteredAllRes = await res.items.filter(passTest);
                                 props.collections.current = [...props.collections.current,...filteredAllRes];
                                 localStorage.setItem("allCollections",JSON.stringify(props.collections.current));
                                 localStorage.setItem("continuation",JSON.stringify(continuation));
                                 apiCallInProgress.current = false;
                                 props.setRowState(props.rowState+1);
                            break;    
                        }
                    }
                    catch (err) {
                        apiCallInProgress.current = false;
                        console.log('err', err);
                    }
                })();
            }
        }
        //check if focus is on first div and left button is pressed. 
        else if((event == "left") && (cardLeftDistance < ((20/100)*window.innerWidth))) {
            //check if first div is displaying the first collection in collections variable. 
            if(!(props.windowState == 0)) {
                windowState(props.windowState-1,props.collectionName);
                props.setWindowState(props.windowState - 1);
            }
        }
    },onEnterPress:() => {
        localStorage.setItem("collectionImg", props.item.meta.content[0].url); 
        localStorage.setItem("collectionId",props.item.meta.name);
        //clear collectorsNFT component cache
        localStorage.removeItem("items-cache");
        localStorage.removeItem("items-continuation-cache");
        localStorage.setItem("items-rowIndex-cache",JSON.stringify(0));
        localStorage.setItem("items-colIndex-cache",JSON.stringify(0));
        localStorage.setItem("items-windowstate",JSON.stringify(0));
        navigate('/CollectorsNFTs/'+props.item.id+'/'+props.item.meta.name)
    }});
    return(
        <div className={"M-card marketplace-card-focusable "+(focused?"M-card-focus":"")} ref={ref}>
            <div className="MarketPlaceImage">
                <MarketPlaceImage
                 item={
                    props.item.meta.content.length == 4 ? props.item.meta.content[3].url :
                    props.item.meta.content.length == 3 ? props.item.meta.content[2].url :
                    props.item.meta.content.length == 2 ? props.item.meta.content[1].url :
                    props.item.meta.content[0].url 
                 }
                 key={props.item.meta.content[0].url}
                  />
            </div>
            <div className="textAlignLeft custom-center">{props.item.meta.name}</div>
    </div>
    )
}

function LoaderCards() {
    const { ref, focused } = useFocusable();
    return (
        <div className={"M-card marketplace-card-focusable "+(focused?"M-card-focus ":"")+((process.env.REACT_APP_SERVER_TYPE == "prodAndroid" || process.env.REACT_APP_SERVER_TYPE == "accAndroid")?"skeleton-android":"skeleton-linux")} ref={ref}>
        </div>    
    )
}







/**
 * This function caches the window state.
 * @param {*} windowState 
 * @param {*} collectionName 
 */
function windowState(windowState,collectionName) {
    switch(collectionName) {
        case "Leaderboard Collections":
            localStorage.setItem("leaderboardWindowState",JSON.stringify(windowState));
            break;
        case "All Collections":
            localStorage.setItem("allCollectionsWindowState",JSON.stringify(windowState));
            break;
        case "Trending Collections":
            localStorage.setItem("trendingCollectionsWindowState",JSON.stringify(windowState));
            break;    
        default:
            console.log("error");
    }
}

/**
 * This function caches the index of the cardsCount array in the current window state. This can be used to set the focus after collections are retrieved from cache.
 * @param {*} index 
 * @param {*} collectionName 
 */
function setWindowStateIndex(index,collectionName) {
    localStorage.setItem("windowStateIndex",JSON.stringify(index));
    localStorage.setItem("windowStateIndexType",JSON.stringify(collectionName));
}

/**
 * This function retrieves collections from the cache if available else make an api call to get collections.
 * This function returns an object that contains the windowstate and collections.
 */
function getCollections(collectionName) {
    return new Promise(async (resolve, reject) => {
            let windowstate = 0;
            let collections;

            const {cachedcollections,cachedwindowstate} = getCachedData(collectionName);//continuation and offset uses same variable cachedcontinuation. 
            
            let res;

            if(cachedcollections.length != 0) {
                collections = cachedcollections;
                windowstate = cachedwindowstate;
            }      
            else {
                    try {
                        switch(collectionName) {
                            case "All Collections":
                                res = await getAllCollections({continuation: continuation});
                                continuation = res.continuation;
                                collections = res.items.filter(passTest);
                                if(collections.length < 7) {
                                    res = await getAllCollections({continuation: continuation});
                                    continuation = res.continuation;
                                    let newcollections = res.items.filter(passTest);
                                    collections = [...collections,...newcollections];
                                }
                                localStorage.setItem("allCollections",JSON.stringify(collections));
                                localStorage.setItem("continuation",JSON.stringify(continuation));
                                break;
                            case "Leaderboard Collections":
                                res= await getCollectionsLeaderboard({offset:offsetLeaderboard});
                                offsetLeaderboard += 10;
                                collections = res.filter(passTest);
                                localStorage.setItem("collectionLeaderBoard",JSON.stringify(collections));
                                localStorage.setItem("offsetLeaderboard",JSON.stringify(offsetLeaderboard));
                                break;
                            case "Trending Collections":
                                res = await getTrendingCollections({continuation: trendingContinuation});
                                trendingContinuation = res.continuation;
                                collections = res.items.filter(passTest);
                                localStorage.setItem("Collectionstrending", JSON.stringify(collections)); 
                                localStorage.setItem("trendingContinuation", JSON.stringify(trendingContinuation)); 
                                break;
                            default:
                                console.log("default selected in getCollections()");
                        }
                    }
                    catch(error) {
                        reject(error);
                    }
            } 
                  resolve({collections:collections,windowstate:windowstate});
    })
}


/**
 * This functions sets the continuation and offset global variable if continuation and offset is present
 * in the cache and returns the below.
 * @param {*} collectionName 
 * @returns The collections and the windowState in cache.
 */
function getCachedData(collectionName) {
    let cachedcollections;
    let cachedwindowstate;
    switch(collectionName) {
        case "All Collections":
         let cachedcontinuation = JSON.parse(localStorage.getItem("continuation"));
         cachedcollections = JSON.parse(localStorage.getItem("allCollections"));
         cachedwindowstate = JSON.parse(localStorage.getItem("allCollectionsWindowState"));
         if(cachedcontinuation !=null
            && cachedcontinuation != "null"
             && cachedcollections != null
              && cachedcollections != "null") {
                continuation = cachedcontinuation;
                if(cachedwindowstate != null && cachedwindowstate !="null") {
                    return({cachedcollections:cachedcollections,cachedwindowstate:cachedwindowstate});
                }
                return({cachedcollections:cachedcollections,cachedwindowstate:0});//if some issue with window state.
              }
              else {
                return({cachedcollections:[],cachedwindowstate:0});
              }
        case "Leaderboard Collections":
         let cachedoffsetleaderboard = JSON.parse(localStorage.getItem("offsetLeaderboard"));
         cachedcollections = JSON.parse(localStorage.getItem("collectionLeaderBoard"));
         cachedwindowstate = JSON.parse(localStorage.getItem("leaderboardWindowState"));
         if(cachedoffsetleaderboard !=null
            && cachedoffsetleaderboard != "null"
             && cachedcollections != null
              && cachedcollections != "null") {
                offsetLeaderboard = cachedoffsetleaderboard;
                if(cachedwindowstate != null && cachedwindowstate !="null") {
                    return({cachedcollections:cachedcollections,cachedwindowstate:cachedwindowstate});
                }
                return({cachedcollections:cachedcollections,cachedwindowstate:0});//if some issue with window state.
              }
              else {
                return({cachedcollections:[],cachedwindowstate:0});
              }
        case "Trending Collections":
         let trendingcontinuation = JSON.parse(localStorage.getItem("trendingContinuation"));
         cachedcollections = JSON.parse(localStorage.getItem("Collectionstrending"));
         cachedwindowstate = JSON.parse(localStorage.getItem("trendingCollectionsWindowState"));
         if(trendingcontinuation !=null
            && trendingcontinuation != "null"
             && cachedcollections != null
              && cachedcollections != "null") {
                trendingContinuation = trendingcontinuation;
                if(cachedwindowstate != null && cachedwindowstate !="null") {
                    return({cachedcollections:cachedcollections,cachedwindowstate:cachedwindowstate});
                }
                return({cachedcollections:cachedcollections,cachedwindowstate:0});//if some issue with window state.
              }
              else {
                return({cachedcollections:[],cachedwindowstate:0});//cache contains nothing
              }   
        default:
            console.log("getCachedDate() selected default");
    }
}


