import '../../css/imageComponentsCss/myCollectionImage.css';
import  marketPlacePlaceHolder  from '../../images/marketPlacePlaceHolder.svg';

export function MarketPlaceImage(props) {
  return (<img
    onError={({ currentTarget }) => {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src=  marketPlacePlaceHolder;
    }}
    className={((process.env.REACT_APP_SERVER_TYPE == "prodAndroid" || process.env.REACT_APP_SERVER_TYPE == "accAndroid")?"skeleton-android":"skeleton-linux")}
    style={{objectFit:'cover'}}
     src={props.item} />
  );
}
