import Container from "react-bootstrap/esm/Container";
import { useEffect } from "react";
import translations from "./translations";

export function NftFullscreen() {
  useEffect(()=>{
    localStorage.setItem("shouldGoBack",true);
    localStorage.setItem("isDashboard",false);
    console.log("isDashboard",localStorage.getItem("isDashboard"));
    console.log("shouldGoBack",localStorage.getItem("shouldGoBack"));
},[])
  if(!navigator.onLine){
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];
    return(
      <Container style={{"textAlign":"center","marginTop":"20%","color":"wheat","fontSize":"x-large"}}>
        
          <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>
        
      </Container>
     );
   }
  else {
    return (
      <Wrapper/>
    )
  }
}

function Wrapper() {
  let selectedLanguage = localStorage.getItem("selected-language");
  if(selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];

  var dataType = localStorage.getItem("fullScreenImgType");
  console.log(dataType, typeof(dataType));
  if (dataType == "VIDEO") {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            "border-radius": "2.3vw",
            width: "56.25vw",
            height: "100vh",
          }}
        >
          <video autoPlay muted loop style={{ width: "100%", height: "100%" }}>
            <source
              src={localStorage.getItem("fullScreenImg")}
              type="video/webm"
            />
            <source
              src={localStorage.getItem("fullScreenImg")}
              type="video/mp4"
            />
            <p>{translatedStrings?.MAIN_MSG_NFT_CANNOT_PLAY_FILE}</p>
          </video>
        </div>
      </div>
    );
  }
  else if(dataType == "AUDIO") {
    return(
      <div style={{ display: "flex", justifyContent: "center" }}>
       <audio src={localStorage.getItem("fullScreenImg")} autoPlay/>
      </div>
    )
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            "border-radius": "2.3vw",
            width: "56.25vw",
            height: "100vh",
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={localStorage.getItem("fullScreenImg")}
          />
        </div>
      </div>
    );
  }
}
