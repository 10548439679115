import nft_not_available from "../images/nft_not_available.png";
import "../css/slideShow.css";
import { getUserItems } from "../api/FileManagementApi";
import { useState, useRef, useEffect } from "react";
import { MyCollectionImage } from "./imageComponents/MyCollectionImage";
import videoPlaceHolder from '../images/myCollectionVideoPlaceholder.svg';
import {
  useFocusable,
  FocusContext,
} from "@noriginmedia/norigin-spatial-navigation";
import translations from "./translations";

export function SlideShow() {
  // if (!navigator.onLine) {
  //   return (
  //     <Container
  //       style={{
  //         textAlign: "center",
  //         marginTop: "20%",
  //         color: "wheat",
  //         fontSize: "x-large",
  //       }}
  //     >
  //       <span>No Internet connection, content could not be loaded</span>
  //     </Container>
  //   );
  // }
  const [isLoading, setIsLoading] = useState(true);
  const imageArray = useRef([]);
  const [fcdBlckIndx, setFcdBlckIndx] = useState(0);

  useEffect(() => {
    (async function () {
      try {
        imageArray.current = await getUserItems({});
        setIsLoading(false);
      } catch (err) {
        console.log("err", err);
      }
    })();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading.....
      </div>
    );
  }

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <TopBlock image={imageArray.current[fcdBlckIndx]} />
      <BottomRow
        fcdBlckIndx={fcdBlckIndx}
        imageArray={imageArray.current}
        setFcdBlckIndx={setFcdBlckIndx}
      />
    </div>
  );
}

function TopBlock({ image }) {
  let selectedLanguage = localStorage.getItem("selected-language");
  if(selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];

  return (
    <div className="top-image">
      {image.meta.content.length == 2 &&
      image.meta.content[1]["@type"] == "VIDEO" ? (
        <video
          autoPlay
          loop
          src={image.meta.content[1].url}
          type="video/webm"
          className="MyCollectionImg"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = nft_not_available;
          }}
        >
          <p>{translatedStrings?.MAIN_MSG_NFT_CANNOT_PLAY_FILE}</p>
        </video>
      ) : image.meta.content.length == 2 &&
      image.meta.content[1]["@type"] == "AUDIO" ? (  <audio src={image.meta.content[1].url} autoPlay/>) : image.meta.content.length == 1 &&
        image.meta.content[0]["@type"] == "IMAGE" ? (
        <img
          className="image"
          loading="lazy"
          src={image.meta.content[0].url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = nft_not_available;
          }}
          alt="image1"
        />
      ) : image.meta.content.length == 1 &&
        image.meta.content[0]["@type"] == "VIDEO" ? (
        <video
          autoPlay
          muted
          loop
          src={image.meta.content[0].url}
          type="video/webm"
          className="MyCollectionImg"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = nft_not_available;
          }}
        >
          <p>{translatedStrings?.MAIN_MSG_NFT_CANNOT_PLAY_FILE}</p>
        </video>
      ) : (
        ""
      )}
    </div>
  );
}

function BottomRow({ imageArray, fcdBlckIndx, setFcdBlckIndx }) {
  const [hide,setHide] = useState(false);
  const { ref, focusKey, focusSelf, navigateByDirection } = useFocusable({ focusKey: "slide-show-bottom-row",saveLastFocusedChild:false });

  useEffect(()=>{
    focusSelf();
  },[]);

  //auto navigation timer logic
  useEffect(()=>{
    let time = 5000;
    if(document.getElementById("blocks-div").childNodes[fcdBlckIndx].childNodes[0].tagName=="VIDEO") {
      let videoDuration = document.getElementById("blocks-div").childNodes[fcdBlckIndx].childNodes[0].duration;
      isNaN(videoDuration)?time=5000:time=videoDuration*1000;
    }
    let timer = setTimeout(()=>{
      if(fcdBlckIndx < imageArray.length) {
        navigateByDirection('right');
      }
      else {
        focusSelf();
      }
  },time);

  return ()=> {
    clearTimeout(timer);
  }
  },[fcdBlckIndx]);

  //auto hide bottom row logic
  useEffect(()=>{
    let time = 5000;
    let timer = setTimeout(()=> {
      setHide(true);
    },time);

    return () => {
      clearTimeout(timer);
    }
  })

  return (
    <FocusContext.Provider value={focusKey}>
      <div className="blocks-div" id="blocks-div" ref={ref} style={hide?{opacity:0}:{opacity:1}}>
        {imageArray.map((image, index) => {
          return <Block image={image} index={index} setFcdBlckIndx={setFcdBlckIndx} setHide={setHide}/>;
        })}
      </div>
    </FocusContext.Provider>
  );
}

function Block({ image, index, setFcdBlckIndx, setHide }) {

  const sendnftPlayBackStatus = (item_id,market_place,playback_state) => {
    try {
      window.JavaFun.nftPlayBackStatus(item_id,market_place,playback_state);
    }
    catch(error) {
      console.log(error);
    }
  }
  
  const { ref, focused } = useFocusable({onFocus:()=>{
    setFcdBlckIndx(index);
    if(image.meta.content[0]["@type"] == "VIDEO") {
      sendnftPlayBackStatus(image.id,"rarible","0");
    }
  },onArrowPress:()=> {
    setHide(false);
  },onBlur: ()=> {
    if(image.meta.content[0]["@type"] == "VIDEO") {
      sendnftPlayBackStatus(image.id,"rarible","1");
    }
  }});

  return (
    <div className={"custom-center "+(focused?"block-focus":"block")} ref={ref}>
      {image.meta.content[0]["@type"] == "IMAGE" ? 
        <MyCollectionImage item={image.meta.content[0].url} /> : <MyCollectionImage item={videoPlaceHolder} />
      }
    </div>
  );
}
