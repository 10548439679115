const translations = {
    // english
    en: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT Collection',
        MAIN_TI_NFT_COLLECTION: 'NFT Collection',
        MAIN_NFT_MY_NFT: 'My NFTs',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Trending Collections',
        MAIN_NFT_ALL_COLLECTIONS: 'All Collections',
        MAIN_NFT_OWNED_BY: 'Owned by',
        MAIN_NFT_PRICE: 'Price',
        MAIN_NFT_HIGHEST_BID: 'Highest bid',
        MAIN_NFT_ADDRESS: 'Address',
        MAIN_NFT_MY_LIBRARY: 'My Library',
        MAIN_NFT_SIGN_IN: 'Sign in',
        MAIN_NFT_FAVOURITES: 'Favourites',
        MAIN_NFT_PLAY_ALL: 'Play All',
        MAIN_NFT_SIGNED_IN: 'Signed in',
        MAIN_MSG_NFT_NO_INTERNET: 'No Internet connection, content could not be loaded.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Server connection error, content could not be loaded.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Your browser cannot play the provided video file.',
        MAIN_BUTTON_CLOSE: 'Close',
        MAIN_BUTTON_SIGN_OUT: 'Sign out',
        MAIN_PANEL_VERSION_COLON :'Version:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //English Europe (en-rGB)	
    enrGB: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT Collection',
        MAIN_TI_NFT_COLLECTION: 'NFT Collection',
        MAIN_NFT_MY_NFT: 'My NFTs',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Trending Collections',
        MAIN_NFT_ALL_COLLECTIONS: 'All Collections',
        MAIN_NFT_OWNED_BY: 'Owned by',
        MAIN_NFT_PRICE: 'Price',
        MAIN_NFT_HIGHEST_BID: 'Highest bid',
        MAIN_NFT_ADDRESS: 'Address',
        MAIN_NFT_MY_LIBRARY: 'My Library',
        MAIN_NFT_SIGN_IN: 'Sign in',
        MAIN_NFT_FAVOURITES: 'Favourites',
        MAIN_NFT_PLAY_ALL: 'Play All',
        MAIN_NFT_SIGNED_IN: 'Signed in',
        MAIN_MSG_NFT_NO_INTERNET: 'No Internet connection, content could not be loaded.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Server connection error, content could not be loaded.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Your browser cannot play the provided video file.',
        MAIN_BUTTON_CLOSE: 'Close',
        MAIN_BUTTON_SIGN_OUT: 'Sign out',
        MAIN_PANEL_VERSION_COLON :'Version:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Thai (th)
    th: {
        MAIN_APP_NAME_NFT_COLLECTION: 'คอลเลคชัน NFT',
        MAIN_TI_NFT_COLLECTION: 'คอลเลคชัน NFT',
        MAIN_NFT_MY_NFT: 'NFT ของฉัน',
        MAIN_NFT_TRENDING_COLLECTIONS: 'คอลเลคชันที่ได้รับความนิยม"',
        MAIN_NFT_ALL_COLLECTIONS: 'คอลเลคชันทั้งหมด',
        MAIN_NFT_OWNED_BY: 'เป็นเจ้าของโดย',
        MAIN_NFT_PRICE: 'ราคา',
        MAIN_NFT_HIGHEST_BID: 'เสนอราคาสูงสุด',
        MAIN_NFT_ADDRESS: 'ที่อยู่',
        MAIN_NFT_MY_LIBRARY: 'ไลบรารีของฉัน',
        MAIN_NFT_SIGN_IN: 'ลงชื่อเข้าใช้',
        MAIN_NFT_FAVOURITES: 'รายการโปรด',
        MAIN_NFT_PLAY_ALL: 'เล่นทั้งหมด',
        MAIN_NFT_SIGNED_IN: 'ลงชื่อเข้าใช้',
        MAIN_MSG_NFT_NO_INTERNET: 'ไม่มีการเชื่อมต่ออินเตอร์เน็ต ไม่สามารถโหลดเนื้อหาได้',
        MAIN_MSG_NFT_SERVER_ERROR: 'เกิดข้อผิดพลาดในการเชื่อมต่อเซิร์ฟเวอร์ ไม่สามารถโหลดเนื้อหาได้',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'เบราว์เซอร์ของคุณไม่สามารถเล่นไฟล์วิดีโอที่ให้มาได้',
        MAIN_BUTTON_CLOSE: 'ปิด',
        MAIN_BUTTON_SIGN_OUT: 'ออกจากระบบ',
        MAIN_PANEL_VERSION_COLON :'เวอร์ชัน:',
        MAIN_MENU:'เมนู',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Vietnamese (vi)	
    vi: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Bộ sưu tập NFT',
        MAIN_TI_NFT_COLLECTION: 'Bộ sưu tập NFT',
        MAIN_NFT_MY_NFT: 'NFT của tôi',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Tuyển tập thịnh hành',
        MAIN_NFT_ALL_COLLECTIONS: 'Tất cả tuyển tập',
        MAIN_NFT_OWNED_BY: 'Được sở hữu bởi',
        MAIN_NFT_PRICE: 'Giá',
        MAIN_NFT_HIGHEST_BID: 'Giá cao nhất',
        MAIN_NFT_ADDRESS: 'Địa chỉ',
        MAIN_NFT_MY_LIBRARY: 'Thư viện của tôi',
        MAIN_NFT_SIGN_IN: 'Đăng nhập',
        MAIN_NFT_FAVOURITES: 'Mục ưa thích',
        MAIN_NFT_PLAY_ALL: 'Phát tất cả',
        MAIN_NFT_SIGNED_IN: 'Đăng nhập',
        MAIN_MSG_NFT_NO_INTERNET: 'Không có kết nối Internet, không tải được nội dung.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Lỗi kết nối máy chủ, không tải được nội dung.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Trình duyệt của bạn không thể phát tệp video được cung cấp.',
        MAIN_BUTTON_CLOSE: 'Đóng',
        MAIN_BUTTON_SIGN_OUT: 'Đăng xuất',
        MAIN_PANEL_VERSION_COLON :'Phiên bản:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Arabic (ar)
    ar: {
        MAIN_APP_NAME_NFT_COLLECTION: 'مجموعة NFT',
        MAIN_TI_NFT_COLLECTION: 'مجموعة NFT',
        MAIN_NFT_MY_NFT: 'الرموز غير القابلة للاستبدال (NFT) الخاصة بي',
        MAIN_NFT_TRENDING_COLLECTIONS: 'المجموعات الرائجة',
        MAIN_NFT_ALL_COLLECTIONS: 'كل المجموعات',
        MAIN_NFT_OWNED_BY: 'المالك',
        MAIN_NFT_PRICE: 'السعر',
        MAIN_NFT_HIGHEST_BID: 'أعلى عطاء',
        MAIN_NFT_ADDRESS: 'العنوان',
        MAIN_NFT_MY_LIBRARY: 'مكتبتي',
        MAIN_NFT_SIGN_IN: 'تسجيل الدخول',
        MAIN_NFT_FAVOURITES: 'المفضلة',
        MAIN_NFT_PLAY_ALL: 'تشغيل الكل',
        MAIN_NFT_SIGNED_IN: 'تسجيل الدخول',
        MAIN_MSG_NFT_NO_INTERNET: 'لا يوجد اتصال بالإنترنت، يتعذر تحميل المحتوى.',
        MAIN_MSG_NFT_SERVER_ERROR: 'خطأ في الاتصال بالخادم، يتعذر تحميل المحتوى.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'يتعذر على المتصفح تشغيل ملف الفيديو المتوفر.',
        MAIN_BUTTON_CLOSE: 'إغلاق',
        MAIN_BUTTON_SIGN_OUT: 'تسجيل الخروج',
        MAIN_PANEL_VERSION_COLON :'الإصدار:',
        MAIN_MENU:'القائمة',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Chinese (zh)
    zh: {
        MAIN_APP_NAME_NFT_COLLECTION: '您的浏览器无法播放所提供的视频文件。',
        MAIN_TI_NFT_COLLECTION: 'NFT 系列',
        MAIN_NFT_MY_NFT: '我的 NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: '热门系列',
        MAIN_NFT_ALL_COLLECTIONS: '所有系列',
        MAIN_NFT_OWNED_BY: '所有者',
        MAIN_NFT_PRICE: '价格',
        MAIN_NFT_HIGHEST_BID: '最高出价',
        MAIN_NFT_ADDRESS: '地址',
        MAIN_NFT_MY_LIBRARY: '我的库',
        MAIN_NFT_SIGN_IN: '登录',
        MAIN_NFT_FAVOURITES: '个人喜好节目',
        MAIN_NFT_PLAY_ALL: '全部播放',
        MAIN_NFT_SIGNED_IN: '登录',
        MAIN_MSG_NFT_NO_INTERNET: '无互联网连接，内容无法加载。',
        MAIN_MSG_NFT_SERVER_ERROR: '服务器连接错误，内容无法加载。',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: '您的浏览器无法播放所提供的视频文件。',
        MAIN_BUTTON_CLOSE: '关闭',
        MAIN_BUTTON_SIGN_OUT: '注销',
        MAIN_PANEL_VERSION_COLON :'版本:',
        MAIN_MENU:'菜单',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Traditional Chinese (zh-rTW)	
    zhrTW: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT 收藏',
        MAIN_TI_NFT_COLLECTION: 'NFT 收藏',
        MAIN_NFT_MY_NFT: '我的 NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: '',
        MAIN_NFT_ALL_COLLECTIONS: '熱門收藏',
        MAIN_NFT_OWNED_BY: '擁有者：',
        MAIN_NFT_PRICE: '價格',
        MAIN_NFT_HIGHEST_BID: '最高出價',
        MAIN_NFT_ADDRESS: '地址',
        MAIN_NFT_MY_LIBRARY: '我的收藏庫',
        MAIN_NFT_SIGN_IN: '登入',
        MAIN_NFT_FAVOURITES: '喜愛頻道',
        MAIN_NFT_PLAY_ALL: '全部播放',
        MAIN_NFT_SIGNED_IN: '登入',
        MAIN_MSG_NFT_NO_INTERNET: '沒有網際網路連線，無法載入內容。',
        MAIN_MSG_NFT_SERVER_ERROR: '伺服器連線錯誤，無法載入內容。',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: '您的瀏覽器無法播放所提供的影片檔案。',
        MAIN_BUTTON_CLOSE: '關閉',
        MAIN_BUTTON_SIGN_OUT: '登出',
        MAIN_PANEL_VERSION_COLON :'版本:',
        MAIN_MENU:'選單',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Bulgarian (bg)	
    bg: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT колекция',
        MAIN_TI_NFT_COLLECTION: 'NFT колекция',
        MAIN_NFT_MY_NFT: 'Моите NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Набиращи популярност колекции',
        MAIN_NFT_ALL_COLLECTIONS: 'Всички колекции',
        MAIN_NFT_OWNED_BY: 'Собственост на',
        MAIN_NFT_PRICE: 'Цена',
        MAIN_NFT_HIGHEST_BID: 'Най-висока оферта',
        MAIN_NFT_ADDRESS: 'Адрес',
        MAIN_NFT_MY_LIBRARY: 'Моята библиотека',
        MAIN_NFT_SIGN_IN: 'Вписване',
        MAIN_NFT_FAVOURITES: 'Предпочитани',
        MAIN_NFT_PLAY_ALL: 'Пусни всички',
        MAIN_NFT_SIGNED_IN: 'Вписване',
        MAIN_MSG_NFT_NO_INTERNET: 'Няма връзка c интернет, съдържанието не може да бъде заредено.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Грешка във връзката със сървъра, съдържанието не може да бъде заредено.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Вашият браузър не може да възпроизведе предоставения видео файл.',
        MAIN_BUTTON_CLOSE: 'Затвори',
        MAIN_BUTTON_SIGN_OUT: 'Излизане',
        MAIN_PANEL_VERSION_COLON :'Версия:',
        MAIN_MENU:'Меню',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Czech (cs)
    cs: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Kolekce NFT',
        MAIN_TI_NFT_COLLECTION: 'Kolekce NFT',
        MAIN_NFT_MY_NFT: 'Moje NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Trendy kolekce',
        MAIN_NFT_ALL_COLLECTIONS: 'Všechny kolekce',
        MAIN_NFT_OWNED_BY: 'Vlastník',
        MAIN_NFT_PRICE: 'Cena',
        MAIN_NFT_HIGHEST_BID: 'Nejvyšší nabídka',
        MAIN_NFT_ADDRESS: 'Adresa',
        MAIN_NFT_MY_LIBRARY: 'Moje knihovna',
        MAIN_NFT_SIGN_IN: 'Přihlásit se',
        MAIN_NFT_FAVOURITES: 'Oblíbené programy',
        MAIN_NFT_PLAY_ALL: 'Přehrát vše',
        MAIN_NFT_SIGNED_IN: 'Přihlásit se',
        MAIN_MSG_NFT_NO_INTERNET: 'Není k dispozici připojení k internetu, obsah se nepodařilo načíst.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Nelze se připojit k serveru, obsah se nepodařilo načíst.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Váš prohlížeč nezvládne přehrát videoobsah.',
        MAIN_BUTTON_CLOSE: 'Zavřít',
        MAIN_BUTTON_SIGN_OUT: 'Odhlášení',
        MAIN_PANEL_VERSION_COLON :'Verze:',
        MAIN_MENU:'Nabídka',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Danish (da)
    da: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT-samling',
        MAIN_TI_NFT_COLLECTION: 'NFT-samling',
        MAIN_NFT_MY_NFT: `Mine NFT'er`,
        MAIN_NFT_TRENDING_COLLECTIONS: 'Populære samlinger',
        MAIN_NFT_ALL_COLLECTIONS: 'Alle samlinger',
        MAIN_NFT_OWNED_BY: 'Ejet af',
        MAIN_NFT_PRICE: 'Pris',
        MAIN_NFT_HIGHEST_BID: 'Højeste bud',
        MAIN_NFT_ADDRESS: 'Adresse',
        MAIN_NFT_MY_LIBRARY: 'Mit bibliotek',
        MAIN_NFT_SIGN_IN: 'Log på',
        MAIN_NFT_FAVOURITES: 'Favorit-programmer',
        MAIN_NFT_PLAY_ALL: 'Afspil alle',
        MAIN_NFT_SIGNED_IN: 'Log på',
        MAIN_MSG_NFT_NO_INTERNET: 'Ingen internetforbindelse, indholdet kunne ikke indlæses.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Tilslutningsfejl, indholdet kunne ikke indlæses.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Din browser kan ikke afspille videofilen.',
        MAIN_BUTTON_CLOSE: 'Luk',
        MAIN_BUTTON_SIGN_OUT: 'Logg av',
        MAIN_PANEL_VERSION_COLON :'Version:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //German (de)
    de: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT Sammlung',
        MAIN_TI_NFT_COLLECTION: 'NFT Sammlung',
        MAIN_NFT_MY_NFT: 'Meine NFTs',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Angesagte Kollektionen',
        MAIN_NFT_ALL_COLLECTIONS: 'Alle Kollektionen',
        MAIN_NFT_OWNED_BY: 'Eigentum von',
        MAIN_NFT_PRICE: 'Preis',
        MAIN_NFT_HIGHEST_BID: 'Höchstes Gebot',
        MAIN_NFT_ADDRESS: 'Adresse',
        MAIN_NFT_MY_LIBRARY: 'Meine Bibliothek',
        MAIN_NFT_SIGN_IN: 'Anmelden',
        MAIN_NFT_FAVOURITES: 'Favoriten',
        MAIN_NFT_PLAY_ALL: 'Alle wdg.',
        MAIN_NFT_SIGNED_IN: 'Anmelden',
        MAIN_MSG_NFT_NO_INTERNET: 'Keine Internetverbindung. Inhalt konnte nicht geladen werden.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Serververbindungsfehler. Inhalt konnte nicht geladen werden.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Ihr Browser kann die bereitgestellte Videodatei nicht wiedergeben.',
        MAIN_BUTTON_CLOSE: 'Schließen',
        MAIN_BUTTON_SIGN_OUT: 'Abmelden',
        MAIN_PANEL_VERSION_COLON :'Version:',
        MAIN_MENU:'Menü',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Greek (el)
    el: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Συλλογή NFT',
        MAIN_TI_NFT_COLLECTION: 'Συλλογή NFT',
        MAIN_NFT_MY_NFT: `Tα NFT μου`,
        MAIN_NFT_TRENDING_COLLECTIONS: 'Δημοφιλείς συλλογές',
        MAIN_NFT_ALL_COLLECTIONS: 'Όλες οι συλλογές',
        MAIN_NFT_OWNED_BY: 'Ανήκει σε',
        MAIN_NFT_PRICE: 'Τιμή',
        MAIN_NFT_HIGHEST_BID: 'Υψηλότερη προσφορά',
        MAIN_NFT_ADDRESS: 'Διεύθυνση',
        MAIN_NFT_MY_LIBRARY: 'Η βιβλιοθήκη μου',
        MAIN_NFT_SIGN_IN: 'Σύνδεση',
        MAIN_NFT_FAVOURITES: 'Προτιμήσεις',
        MAIN_NFT_PLAY_ALL: 'Αναπαρ. όλων',
        MAIN_NFT_SIGNED_IN: 'Σύνδεση',
        MAIN_MSG_NFT_NO_INTERNET: 'Δεν υπάρχει σύνδεση στο Internet, δεν ήταν δυνατή η φόρτωση περιεχομένου.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Σφάλμα σύνδεσης διακομιστή, δεν ήταν δυνατή η φόρτωση περιεχομένου.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Το πρόγραμμα περιήγησής σας δεν μπορεί να αναπαραγάγει το παρεχόμενο αρχείο βίντεο.',
        MAIN_BUTTON_CLOSE: 'Κλείσιμο',
        MAIN_BUTTON_SIGN_OUT: 'Έξοδος',
        MAIN_PANEL_VERSION_COLON :'Έκδοση:',
        MAIN_MENU:'Μενού',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Spanish (es)
    es: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Colección de NFT',
        MAIN_TI_NFT_COLLECTION: 'Colección de NFT',
        MAIN_NFT_MY_NFT: 'Mis NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Colecciones más populares',
        MAIN_NFT_ALL_COLLECTIONS: 'Todas las colecciones',
        MAIN_NFT_OWNED_BY: 'Propiedad de',
        MAIN_NFT_PRICE: 'Precio',
        MAIN_NFT_HIGHEST_BID: 'Puja más alta',
        MAIN_NFT_ADDRESS: 'Dirección',
        MAIN_NFT_MY_LIBRARY: 'Mi biblioteca',
        MAIN_NFT_SIGN_IN: 'Iniciar sesión',
        MAIN_NFT_FAVOURITES: 'Programas preferidos',
        MAIN_NFT_PLAY_ALL: 'Reproducir todo',
        MAIN_NFT_SIGNED_IN: 'Iniciar sesión',
        MAIN_MSG_NFT_NO_INTERNET: 'No hay conexión a Internet, no se ha podido cargar el contenido.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Error de conexión al servidor, no se ha podido cargar el contenido.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Su navegador no puede reproducir el archivo de vídeo proporcionado.',
        MAIN_BUTTON_CLOSE: 'Cerrar',
        MAIN_BUTTON_SIGN_OUT: 'Calidad señal:',
        MAIN_PANEL_VERSION_COLON :'Versión:',
        MAIN_MENU:'Menú',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //French (fr)
    fr: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Collection NFT',
        MAIN_TI_NFT_COLLECTION: 'Collection NFT',
        MAIN_NFT_MY_NFT: 'Mes NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Collections en tendance',
        MAIN_NFT_ALL_COLLECTIONS: 'Toutes les collections',
        MAIN_NFT_OWNED_BY: 'Propriété de',
        MAIN_NFT_PRICE: 'Prix',
        MAIN_NFT_HIGHEST_BID: 'Enchère la plus élevée',
        MAIN_NFT_ADDRESS: 'Adresse',
        MAIN_NFT_MY_LIBRARY: 'Ma bibliothèque',
        MAIN_NFT_SIGN_IN: 'Se connecter',
        MAIN_NFT_FAVOURITES: 'Favoris',
        MAIN_NFT_PLAY_ALL: 'Lire tout',
        MAIN_NFT_SIGNED_IN: 'Se connecter',
        MAIN_MSG_NFT_NO_INTERNET: `Aucune connexion Internet, le contenu n'a pas pu être chargé.`,
        MAIN_MSG_NFT_SERVER_ERROR: `Erreur de connexion au serveur, le contenu n'a pas pu être chargé.`,
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Votre navigateur ne peut pas lire le fichier vidéo fourni.',
        MAIN_BUTTON_CLOSE: 'Fermer',
        MAIN_BUTTON_SIGN_OUT: 'Déconnexion',
        MAIN_PANEL_VERSION_COLON :'Version:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Croatian (hr)	
    hr: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT kolekcija',
        MAIN_TI_NFT_COLLECTION: 'NFT kolekcija',
        MAIN_NFT_MY_NFT: 'Moji NFT-ovi',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Popularne kolekcije',
        MAIN_NFT_ALL_COLLECTIONS: 'Sve kolekcije',
        MAIN_NFT_OWNED_BY: 'Vlasnik',
        MAIN_NFT_PRICE: 'Cijena',
        MAIN_NFT_HIGHEST_BID: 'Najviša ponuda',
        MAIN_NFT_ADDRESS: 'Adresa',
        MAIN_NFT_MY_LIBRARY: 'Moja biblioteka',
        MAIN_NFT_SIGN_IN: 'Prijava',
        MAIN_NFT_FAVOURITES: 'Omiljeni',
        MAIN_NFT_PLAY_ALL: 'Reproduciraj sve',
        MAIN_NFT_SIGNED_IN: 'Prijava',
        MAIN_MSG_NFT_NO_INTERNET: 'Nema internetske veze, sadržaj se ne može učitati.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Pogreška veze s poslužiteljem, sadržaj se ne može učitati.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Vaš preglednik ne može reproducirati popratnu videodatoteku.',
        MAIN_BUTTON_CLOSE: 'Zatvori',
        MAIN_BUTTON_SIGN_OUT: 'Odjava',
        MAIN_PANEL_VERSION_COLON :'Verzija:',
        MAIN_MENU:'Izbornik',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Italian (it)
    it: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Raccolta NFT',
        MAIN_TI_NFT_COLLECTION: 'Raccolta NFT',
        MAIN_NFT_MY_NFT: 'NFT personali',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Raccolte di tendenza',
        MAIN_NFT_ALL_COLLECTIONS: 'Tutte le raccolte',
        MAIN_NFT_OWNED_BY: 'Di proprietà di',
        MAIN_NFT_PRICE: 'Prezzo',
        MAIN_NFT_HIGHEST_BID: 'Offerta più alta',
        MAIN_NFT_ADDRESS: 'Indirizzo',
        MAIN_NFT_MY_LIBRARY: 'Libreria personale',
        MAIN_NFT_SIGN_IN: 'Accedi',
        MAIN_NFT_FAVOURITES: 'Preferiti',
        MAIN_NFT_PLAY_ALL: 'Riproduci tutti',
        MAIN_NFT_SIGNED_IN: 'Accedi',
        MAIN_MSG_NFT_NO_INTERNET: 'Nessuna connessione a Internet, impossibile caricare il contenuto.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Errore di connessione al server, impossibile caricare il contenuto.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Il browser non è in grado di riprodurre il file video fornito.',
        MAIN_BUTTON_CLOSE: 'Chiudi',
        MAIN_BUTTON_SIGN_OUT: 'Qualità segnale:',
        MAIN_PANEL_VERSION_COLON :'Versione:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Hungarian (hu)
    hu: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT gyűjtemény',
        MAIN_TI_NFT_COLLECTION: 'NFT gyűjtemény',
        MAIN_NFT_MY_NFT: 'Saját NFT-k',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Népszerű gyűjtemények',
        MAIN_NFT_ALL_COLLECTIONS: 'Minden gyűjtemény',
        MAIN_NFT_OWNED_BY: 'Tulajdonos',
        MAIN_NFT_PRICE: 'Ár',
        MAIN_NFT_HIGHEST_BID: 'Legmagasabb ajánlat',
        MAIN_NFT_ADDRESS: 'Cím',
        MAIN_NFT_MY_LIBRARY: 'Saját könyvtár',
        MAIN_NFT_SIGN_IN: 'Bejelentkezés',
        MAIN_NFT_FAVOURITES: 'Kedvenc programok',
        MAIN_NFT_PLAY_ALL: 'Összes lejátszása',
        MAIN_NFT_SIGNED_IN: 'Bejelentkezés',
        MAIN_MSG_NFT_NO_INTERNET: 'Nincs internetkapcsolat, a tartalom nem tölthető be.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Szervercsatlakozási hiba, a tartalom nem tölthető be.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'A böngészője nem játssza le a támogatott videofájlt.',
        MAIN_BUTTON_CLOSE: 'Bezár',
        MAIN_BUTTON_SIGN_OUT: 'Jelminőség:',
        MAIN_PANEL_VERSION_COLON :'Változat:',
        MAIN_MENU:'Menü',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Dutch (nl)
    nl: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT-collectie',
        MAIN_TI_NFT_COLLECTION: 'NFT-collectie',
        MAIN_NFT_MY_NFT: `Mijn NFT's`,
        MAIN_NFT_TRENDING_COLLECTIONS: 'Trending collecties',
        MAIN_NFT_ALL_COLLECTIONS: 'Alle collecties',
        MAIN_NFT_OWNED_BY: 'Eigendom van',
        MAIN_NFT_PRICE: 'Prijs',
        MAIN_NFT_HIGHEST_BID: 'Hoogste bod',
        MAIN_NFT_ADDRESS: 'Adres',
        MAIN_NFT_MY_LIBRARY: 'Mijn bibliotheek',
        MAIN_NFT_SIGN_IN: 'Aanmelden',
        MAIN_NFT_FAVOURITES: 'Favorieten',
        MAIN_NFT_PLAY_ALL: 'Alles afspelen',
        MAIN_NFT_SIGNED_IN: 'Aanmelden',
        MAIN_MSG_NFT_NO_INTERNET: 'Geen internetverbinding, inhoud kan niet worden geladen.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Fout bij verbinding maken met server, inhoud kan niet worden geladen.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Uw browser kan het opgegeven videobestand niet afspelen.',
        MAIN_BUTTON_CLOSE: 'Sluiten',
        MAIN_BUTTON_SIGN_OUT: 'Afmelden',
        MAIN_PANEL_VERSION_COLON :'Versie:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',

    },
    //Norwegian (no)
    no: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT-samling',
        MAIN_TI_NFT_COLLECTION: 'NFT-samling',
        MAIN_NFT_MY_NFT: 'Mine NFT-er',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Populære kolleksjoner',
        MAIN_NFT_ALL_COLLECTIONS: 'Alle kolleksjoner',
        MAIN_NFT_OWNED_BY: 'Eies av',
        MAIN_NFT_PRICE: 'Pris',
        MAIN_NFT_HIGHEST_BID: 'Høyeste bud',
        MAIN_NFT_ADDRESS: 'Adresse',
        MAIN_NFT_MY_LIBRARY: 'Mitt bibliotek',
        MAIN_NFT_SIGN_IN: 'Logg på',
        MAIN_NFT_FAVOURITES: 'Foretrukne program',
        MAIN_NFT_PLAY_ALL: 'Spill alle',
        MAIN_NFT_SIGNED_IN: 'Logg på',
        MAIN_MSG_NFT_NO_INTERNET: 'Ingen Internett-tilkobling, innhold kan ikke lastes inn.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Servertilkoblingsfeil, innhold kan ikke lastes inn.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Nettleseren kan ikke spille av den angitte videofilen.',
        MAIN_BUTTON_CLOSE: 'Lukk',
        MAIN_BUTTON_SIGN_OUT: 'Signalkvalitet:',
        MAIN_PANEL_VERSION_COLON :'Versjon:',
        MAIN_MENU:'Meny',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Polish (pl)
    pl: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Zbiór NFT',
        MAIN_TI_NFT_COLLECTION: 'Zbiór NFT',
        MAIN_NFT_MY_NFT: 'Moje elementy NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Kolekcje na topie',
        MAIN_NFT_ALL_COLLECTIONS: 'Wszystkie kolekcje',
        MAIN_NFT_OWNED_BY: 'Własność',
        MAIN_NFT_PRICE: 'Cena',
        MAIN_NFT_HIGHEST_BID: 'Najwyższa oferta',
        MAIN_NFT_ADDRESS: 'Adres',
        MAIN_NFT_MY_LIBRARY: 'Moja biblioteka',
        MAIN_NFT_SIGN_IN: 'Zaloguj',
        MAIN_NFT_FAVOURITES: 'Ulubione programy',
        MAIN_NFT_PLAY_ALL: 'Odtwórz wsz.',
        MAIN_NFT_SIGNED_IN: 'Zaloguj',
        MAIN_MSG_NFT_NO_INTERNET: 'Brak połączenia z Internetem, nie udało się wczytać zawartości.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Błąd połączenia z serwerem, nie udało się wczytać zawartości.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Przeglądarka nie może odtworzyć dostarczonego pliku wideo.',
        MAIN_BUTTON_CLOSE: 'Zamknij',
        MAIN_BUTTON_SIGN_OUT: 'Wyloguj',
        MAIN_PANEL_VERSION_COLON :'Wersja:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Portuguese (pt)	
    pt: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Colecção NFT',
        MAIN_TI_NFT_COLLECTION: 'Colecção NFT',
        MAIN_NFT_MY_NFT: 'Os meus NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Colecções populares',
        MAIN_NFT_ALL_COLLECTIONS: 'Todas as colecções',
        MAIN_NFT_OWNED_BY: 'Propriedade de',
        MAIN_NFT_PRICE: 'Preço',
        MAIN_NFT_HIGHEST_BID: 'Maior oferta',
        MAIN_NFT_ADDRESS: 'Endereço',
        MAIN_NFT_MY_LIBRARY: 'Biblioteca',
        MAIN_NFT_SIGN_IN: 'Iniciar sessão',
        MAIN_NFT_FAVOURITES: 'Favoritos',
        MAIN_NFT_PLAY_ALL: 'Reprod. todos',
        MAIN_NFT_SIGNED_IN: 'Iniciar sessão',
        MAIN_MSG_NFT_NO_INTERNET: 'Sem ligação à Internet. Não foi possível carregar o conteúdo.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Erro de ligação ao servidor. Não foi possível carregar o conteúdo.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'O seu navegador não consegue reproduzir o ficheiro de vídeo fornecido.',
        MAIN_BUTTON_CLOSE: 'Fechar',
        MAIN_BUTTON_SIGN_OUT: 'Terminar sessão',
        MAIN_PANEL_VERSION_COLON :'Versão:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //Romanian (ro)
    ro: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Colecţia NFT',
        MAIN_TI_NFT_COLLECTION: 'Colecţia NFT',
        MAIN_NFT_MY_NFT: 'NFT-urile mele',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Colecţii populare',
        MAIN_NFT_ALL_COLLECTIONS: 'Toate colecţiile',
        MAIN_NFT_OWNED_BY: 'Proprietar',
        MAIN_NFT_PRICE: 'Preţ',
        MAIN_NFT_HIGHEST_BID: 'Cea mai mare ofertă',
        MAIN_NFT_ADDRESS: 'Adresă',
        MAIN_NFT_MY_LIBRARY: 'Biblioteca mea',
        MAIN_NFT_SIGN_IN: 'Conectare',
        MAIN_NFT_FAVOURITES: 'Programe favorite',
        MAIN_NFT_PLAY_ALL: 'Redare toate',
        MAIN_NFT_SIGNED_IN: 'Conectare',
        MAIN_MSG_NFT_NO_INTERNET: 'Nu există conexiune la internet. Conţinutul nu a putut fi încărcat.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Eroare de conexiune la server. Conţinutul nu a putut fi încărcat.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Browserul dvs. nu poate reda fişierul video furnizat.',
        MAIN_BUTTON_CLOSE: 'Închis',
        MAIN_BUTTON_SIGN_OUT: 'Deconectare',
        MAIN_PANEL_VERSION_COLON :'Vers.:',
        MAIN_MENU:'Meniu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    //     Russian (ru)	
    ru: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Коллекция NFT',
        MAIN_TI_NFT_COLLECTION: 'Коллекция NFT',
        MAIN_NFT_MY_NFT: 'Мои NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Популярные коллекции',
        MAIN_NFT_ALL_COLLECTIONS: 'Все коллекции',
        MAIN_NFT_OWNED_BY: 'Владелец',
        MAIN_NFT_PRICE: 'Цена',
        MAIN_NFT_HIGHEST_BID: 'Максимальная ставка',
        MAIN_NFT_ADDRESS: 'Адрес',
        MAIN_NFT_MY_LIBRARY: 'Моя библиотека',
        MAIN_NFT_SIGN_IN: 'Вход',
        MAIN_NFT_FAVOURITES: 'Избранные',
        MAIN_NFT_PLAY_ALL: 'Воспроизв. все',
        MAIN_NFT_SIGNED_IN: 'Вход',
        MAIN_MSG_NFT_NO_INTERNET: 'Нет подключения к Интернету, не удается загрузить материалы.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Ошибка подключения к серверу, не удается загрузить материалы.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Ваш браузер не может воспроизвести выбранный видеофайл.',
        MAIN_BUTTON_CLOSE: 'Закрыть',
        MAIN_BUTTON_SIGN_OUT: 'Выход',
        MAIN_PANEL_VERSION_COLON :'Версия:',
        MAIN_MENU:'Меню',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Slovak (sk)	
    sk: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT Collection',
        MAIN_TI_NFT_COLLECTION: 'NFT Collection',
        MAIN_NFT_MY_NFT: 'Moje položky NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Najpopulárnejšie kolekcie',
        MAIN_NFT_ALL_COLLECTIONS: 'Všetky kolekcie',
        MAIN_NFT_OWNED_BY: 'Vlastnené',
        MAIN_NFT_PRICE: 'Cena',
        MAIN_NFT_HIGHEST_BID: 'Najvyššia ponuka',
        MAIN_NFT_ADDRESS: 'Adresa',
        MAIN_NFT_MY_LIBRARY: 'Moja knižnica',
        MAIN_NFT_SIGN_IN: 'Prihlásiť',
        MAIN_NFT_FAVOURITES: 'Obľúbené programy',
        MAIN_NFT_PLAY_ALL: 'Prehrať všetko',
        MAIN_NFT_SIGNED_IN: 'Prihlásiť',
        MAIN_MSG_NFT_NO_INTERNET: 'Nemáte pripojenie na internet, obsah sa nedal načítať.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Chyba pripojenia servera, obsah sa nedal načítať.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Prehliadač nemôže prehrať poskytnutý súbor s videom.',
        MAIN_BUTTON_CLOSE: 'Zavrieť',
        MAIN_BUTTON_SIGN_OUT: 'Odhlásiť sa',
        MAIN_PANEL_VERSION_COLON :'Verzia:',
        MAIN_MENU:'Ponuka',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Slovenian (sl)	
    sl: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Zbirka NFT',
        MAIN_TI_NFT_COLLECTION: 'Zbirka NFT',
        MAIN_NFT_MY_NFT: 'Moji žetoni NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Priljubljene zbirke',
        MAIN_NFT_ALL_COLLECTIONS: 'Vse zbirke',
        MAIN_NFT_OWNED_BY: 'V lasti',
        MAIN_NFT_PRICE: 'Cena',
        MAIN_NFT_HIGHEST_BID: 'Najvišja ponudba',
        MAIN_NFT_ADDRESS: 'Naslov',
        MAIN_NFT_MY_LIBRARY: 'Moja knjižnica',
        MAIN_NFT_SIGN_IN: 'Prijava',
        MAIN_NFT_FAVOURITES: 'Priljubljeni',
        MAIN_NFT_PLAY_ALL: 'Predvajaj vse',
        MAIN_NFT_SIGNED_IN: 'Prijava',
        MAIN_MSG_NFT_NO_INTERNET: 'Ni internetne povezave, vsebine ni bilo mogoče naložiti.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Napaka pri povezovanju s strežnikom, vsebine ni bilo mogoče naložiti.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Brskalnik ne more predvajati priložene videodatoteke.',
        MAIN_BUTTON_CLOSE: 'Zapri',
        MAIN_BUTTON_SIGN_OUT: 'Odjava',
        MAIN_PANEL_VERSION_COLON :'Različica:',
        MAIN_MENU:'Meni',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Serbian (sr)	
    sr: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT kolekcija',
        MAIN_TI_NFT_COLLECTION: 'NFT kolekcija',
        MAIN_NFT_MY_NFT: 'Moje NFT kolekcije',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Kolekcije u trendu',
        MAIN_NFT_ALL_COLLECTIONS: 'Sve kolekcije',
        MAIN_NFT_OWNED_BY: 'Vlasnik',
        MAIN_NFT_PRICE: 'Cena',
        MAIN_NFT_HIGHEST_BID: 'Najviša ponuda',
        MAIN_NFT_ADDRESS: 'Adresa',
        MAIN_NFT_MY_LIBRARY: 'Moja biblioteka',
        MAIN_NFT_SIGN_IN: 'Prijavi me',
        MAIN_NFT_FAVOURITES: 'Omiljeno',
        MAIN_NFT_PLAY_ALL: 'Pusti sve',
        MAIN_NFT_SIGNED_IN: 'Prijavi me',
        MAIN_MSG_NFT_NO_INTERNET: 'Niste povezani na internet, nije moguće učitati sadržaj.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Greška u vezi sa serverom, nije moguće učitati sadržaj.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Vaš pregledač ne može da reprodukuje poslatu video datoteku.',
        MAIN_BUTTON_CLOSE: 'Zatvori',
        MAIN_BUTTON_SIGN_OUT: 'Odjavi me',
        MAIN_PANEL_VERSION_COLON :'Verzija:',
        MAIN_MENU:'Meni',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Finnish (fi)	
    fi: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT-kokoelma',
        MAIN_TI_NFT_COLLECTION: 'NFT-kokoelma',
        MAIN_NFT_MY_NFT: 'Omat NFT:t',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Trendikkäät kokoelmat',
        MAIN_NFT_ALL_COLLECTIONS: 'Kaikki kokoelmat',
        MAIN_NFT_OWNED_BY: 'Omistaja',
        MAIN_NFT_PRICE: 'Price',
        MAIN_NFT_HIGHEST_BID: 'Korkein tarjous',
        MAIN_NFT_ADDRESS: 'Osoite',
        MAIN_NFT_MY_LIBRARY: 'Oma kirjasto',
        MAIN_NFT_SIGN_IN: 'Kirjaudu sisään',
        MAIN_NFT_FAVOURITES: 'Suosikkiohjelmat',
        MAIN_NFT_PLAY_ALL: 'Toista kaikki',
        MAIN_NFT_SIGNED_IN: 'Kirjaudu sisään',
        MAIN_MSG_NFT_NO_INTERNET: 'Ei Internet-yhteyttä. Sisältöä ei voitu ladata.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Palvelimen yhteysvirhe. Sisältöä ei voitu ladata.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Selaimesi ei voi toistaa kyseistä videotiedostoa.',
        MAIN_BUTTON_CLOSE: 'Sulje',
        MAIN_BUTTON_SIGN_OUT: 'Kirjaudu ulos',
        MAIN_PANEL_VERSION_COLON :'Versio:',
        MAIN_MENU:'Valikko',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Swedish (sv)	
    sv: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT-samling',
        MAIN_TI_NFT_COLLECTION: 'NFT-samling',
        MAIN_NFT_MY_NFT: 'Mina NFT-tillgångar',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Trendsamlingar',
        MAIN_NFT_ALL_COLLECTIONS: 'Alla samlingar',
        MAIN_NFT_OWNED_BY: 'Ägs av',
        MAIN_NFT_PRICE: 'Pris',
        MAIN_NFT_HIGHEST_BID: 'Högsta bud',
        MAIN_NFT_ADDRESS: 'Adress',
        MAIN_NFT_MY_LIBRARY: 'Mitt bibliotek',
        MAIN_NFT_SIGN_IN: 'Logga in',
        MAIN_NFT_FAVOURITES: 'Favoritprogram',
        MAIN_NFT_PLAY_ALL: 'Spela upp alla',
        MAIN_NFT_SIGNED_IN: 'Logga in',
        MAIN_MSG_NFT_NO_INTERNET: 'Ingen internetanslutning. Det gick inte att läsa in innehållet.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Serveranslutningsfel. Det gick inte att läsa in innehållet.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Det går inte att spela upp den angivna videofilen i din webbläsare.',
        MAIN_BUTTON_CLOSE: 'Avsluta',
        MAIN_BUTTON_SIGN_OUT: 'Signalkvalitet:',
        MAIN_PANEL_VERSION_COLON :'Version:',
        MAIN_MENU:'Meny',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Turkish (tr)	
    tr: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT Koleksiyonu',
        MAIN_TI_NFT_COLLECTION: 'NFT Koleksiyonu',
        MAIN_NFT_MY_NFT: `NFT'lerim`,
        MAIN_NFT_TRENDING_COLLECTIONS: 'Popüler Koleksiyonlar',
        MAIN_NFT_ALL_COLLECTIONS: 'Tüm Koleksiyonlar',
        MAIN_NFT_OWNED_BY: 'Sahibi',
        MAIN_NFT_PRICE: 'Fiyat',
        MAIN_NFT_HIGHEST_BID: 'En yüksek teklif',
        MAIN_NFT_ADDRESS: 'Adres',
        MAIN_NFT_MY_LIBRARY: 'Kitaplığım',
        MAIN_NFT_SIGN_IN: 'Oturum aç',
        MAIN_NFT_FAVOURITES: 'Favoriler',
        MAIN_NFT_PLAY_ALL: 'Tümünü Oynat',
        MAIN_NFT_SIGNED_IN: 'Oturum aç',
        MAIN_MSG_NFT_NO_INTERNET: 'İnternet bağlantısı yok, içerik yüklenemedi.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Sunucu bağlantısı hatası meydana geldi, içerik yüklenemedi.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Tarayıcınız, sağlanan video dosyasını oynatamıyor.',
        MAIN_BUTTON_CLOSE: 'Kapat',
        MAIN_BUTTON_SIGN_OUT: 'Oturumu kapat',
        MAIN_PANEL_VERSION_COLON :'Sürüm:',
        MAIN_MENU:'Menü',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Estonian (et)	
    et: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT kogu',
        MAIN_TI_NFT_COLLECTION: 'NFT kogu',
        MAIN_NFT_MY_NFT: `Minu NFT-d`,
        MAIN_NFT_TRENDING_COLLECTIONS: 'Populaarsed kogud',
        MAIN_NFT_ALL_COLLECTIONS: 'õik kogud',
        MAIN_NFT_OWNED_BY: 'Omanik',
        MAIN_NFT_PRICE: 'Hind',
        MAIN_NFT_HIGHEST_BID: 'Suurim pakkumine',
        MAIN_NFT_ADDRESS: 'Aadress',
        MAIN_NFT_MY_LIBRARY: 'Minu teek',
        MAIN_NFT_SIGN_IN: 'Logi sisse',
        MAIN_NFT_FAVOURITES: 'Lemmikud',
        MAIN_NFT_PLAY_ALL: 'Esita kõik',
        MAIN_NFT_SIGNED_IN: 'Logi sisse',
        MAIN_MSG_NFT_NO_INTERNET: 'Internetiühendus puudub, sisu ei õnnestunud laadida.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Serveriühenduse tõrge, sisu ei õnnestunud laadida.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Teie brauser ei saa antud videofaili esitada.',
        MAIN_BUTTON_CLOSE: 'Sulge',
        MAIN_BUTTON_SIGN_OUT: 'Signaali kvaliteet:',
        MAIN_PANEL_VERSION_COLON :'Versioon:',
        MAIN_MENU:'Menüü',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Ukrainian (uk)	
    uk: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Колекція NFT',
        MAIN_TI_NFT_COLLECTION: 'Колекція NFT',
        MAIN_NFT_MY_NFT: 'Мої NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Популярні колекції',
        MAIN_NFT_ALL_COLLECTIONS: 'Усі колекції',
        MAIN_NFT_OWNED_BY: 'Власник:',
        MAIN_NFT_PRICE: 'Ціна',
        MAIN_NFT_HIGHEST_BID: 'Найвища ставка',
        MAIN_NFT_ADDRESS: 'Адреса',
        MAIN_NFT_MY_LIBRARY: 'Моя бібліотека',
        MAIN_NFT_SIGN_IN: 'Вхід',
        MAIN_NFT_FAVOURITES: 'Вибране',
        MAIN_NFT_PLAY_ALL: 'Відтворити все',
        MAIN_NFT_SIGNED_IN: 'Вхід',
        MAIN_MSG_NFT_NO_INTERNET: 'Немає інтернет-з’єднання, неможливо завантажити контент.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Помилка з’єднання з сервером, неможливо завантажити контент.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Ваш браузер не може відтворити наданий відеофайл.',
        MAIN_BUTTON_CLOSE: 'Закрити',
        MAIN_BUTTON_SIGN_OUT: 'Вихід',
        MAIN_PANEL_VERSION_COLON :'Версія:',
        MAIN_MENU:'Меню',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Latvian (lv)	
    lv: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT kolekcija',
        MAIN_TI_NFT_COLLECTION: 'NFT kolekcija',
        MAIN_NFT_MY_NFT: 'Mani NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Populāras kolekcijas',
        MAIN_NFT_ALL_COLLECTIONS: 'Visas kolekcijas',
        MAIN_NFT_OWNED_BY: 'Īpašnieks',
        MAIN_NFT_PRICE: 'Cena',
        MAIN_NFT_HIGHEST_BID: 'Augstākā cena',
        MAIN_NFT_ADDRESS: 'Adrese',
        MAIN_NFT_MY_LIBRARY: 'Mana bibliotēka',
        MAIN_NFT_SIGN_IN: 'Pierakstīties',
        MAIN_NFT_FAVOURITES: 'Favorīti',
        MAIN_NFT_PLAY_ALL: 'Atskaņot visu',
        MAIN_NFT_SIGNED_IN: 'Pierakstīties',
        MAIN_MSG_NFT_NO_INTERNET: 'Nav interneta savienojuma, saturu nevarēja ielādēt.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Servera savienojuma kļūda, saturu nevarēja ielādēt.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Jūsu pārlūkprogramma nevar atskaņot nodrošināto video failu.',
        MAIN_BUTTON_CLOSE: 'Aizvērt',
        MAIN_BUTTON_SIGN_OUT: 'Signāla kvalitāte:',
        MAIN_PANEL_VERSION_COLON :'Versija:',
        MAIN_MENU:'Izvēlne',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Kazakh (kk)	
    kk: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT жинағы',
        MAIN_TI_NFT_COLLECTION: 'NFT жинағы',
        MAIN_NFT_MY_NFT: 'Менің NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Трендегі жинақтар',
        MAIN_NFT_ALL_COLLECTIONS: 'Барлық жинақ',
        MAIN_NFT_OWNED_BY: 'Иесі',
        MAIN_NFT_PRICE: 'Бағасы',
        MAIN_NFT_HIGHEST_BID: 'Ең жоғары баға',
        MAIN_NFT_ADDRESS: 'Мекенжайы',
        MAIN_NFT_MY_LIBRARY: 'Менің кітапханам',
        MAIN_NFT_SIGN_IN: 'Кіру',
        MAIN_NFT_FAVOURITES: 'Таңдалған',
        MAIN_NFT_PLAY_ALL: 'Барлығын',
        MAIN_NFT_SIGNED_IN: 'Кіру',
        MAIN_MSG_NFT_NO_INTERNET: 'Интернет байланысы жоқ, мазмұнды жүктеу мүмкін болмады.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Серверге қосылу қатесі, мазмұнды жүктеу мүмкін болмады.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Браузеріңіз берілген бейне файлды ойната алмайды.',
        MAIN_BUTTON_CLOSE: 'Жабу',
        MAIN_BUTTON_SIGN_OUT: 'Шығу',
        MAIN_PANEL_VERSION_COLON :'Нұсқа:',
        MAIN_MENU:'Мәзір',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Lithuanian (lt)	
    lt: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT kolekcija',
        MAIN_TI_NFT_COLLECTION: 'NFT kolekcija',
        MAIN_NFT_MY_NFT: 'Mano NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Populiarios kolekcijos',
        MAIN_NFT_ALL_COLLECTIONS: 'Visos kolekcijos',
        MAIN_NFT_OWNED_BY: 'Priklauso',
        MAIN_NFT_PRICE: 'Kaina',
        MAIN_NFT_HIGHEST_BID: 'Didžiausia pasiūlyta kaina',
        MAIN_NFT_ADDRESS: 'Adresas',
        MAIN_NFT_MY_LIBRARY: 'Mano biblioteka',
        MAIN_NFT_SIGN_IN: 'Prisijungti',
        MAIN_NFT_FAVOURITES: 'Mėgstamiausieji',
        MAIN_NFT_PLAY_ALL: 'Leisti visus',
        MAIN_NFT_SIGNED_IN: 'Prisijungti',
        MAIN_MSG_NFT_NO_INTERNET: 'Nėra interneto ryšio ir turinio nepavyko įkelti.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Ryšio su serveriu klaida ir turinio nepavyko įkelti.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Jūsų naršyklė negali atkurti pateikto vaizdo įrašo failo.',
        MAIN_BUTTON_CLOSE: 'Uždaryti',
        MAIN_BUTTON_SIGN_OUT: 'Atsijungti',
        MAIN_PANEL_VERSION_COLON :'Versija:',
        MAIN_MENU:'Meniu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Irish (ga)	
    ga: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Bailiúchán NFT',
        MAIN_TI_NFT_COLLECTION: 'Bailiúchán NFT',
        MAIN_NFT_MY_NFT: 'Mo NFTanna',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Bailiúcháin Treochta',
        MAIN_NFT_ALL_COLLECTIONS: 'Gach Bailiúcháin',
        MAIN_NFT_OWNED_BY: 'Faoi úinéireacht',
        MAIN_NFT_PRICE: 'Praghas',
        MAIN_NFT_HIGHEST_BID: 'An tairiscint is airde',
        MAIN_NFT_ADDRESS: 'Seoladh',
        MAIN_NFT_MY_LIBRARY: 'Mo Leabharlann',
        MAIN_NFT_SIGN_IN: 'Sínigh isteach',
        MAIN_NFT_FAVOURITES: 'Rogha chláir',
        MAIN_NFT_PLAY_ALL: 'Seinn Gach Ceann',
        MAIN_NFT_SIGNED_IN: 'Sínigh isteach',
        MAIN_MSG_NFT_NO_INTERNET: 'Níl aon nasc idirlín ann, ní fhéadfaí an t-ábhar a lódáil.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Earráid nasc freastalaí, níorbh fhéidir inneachar a luchtú.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Ní féidir le do bhrabhsálaí an comhad físe atá curtha ar fáil a sheinm.',
        MAIN_BUTTON_CLOSE: 'Dún',
        MAIN_BUTTON_SIGN_OUT: 'Sínigh amach:',
        MAIN_PANEL_VERSION_COLON :'Leagan:',
        MAIN_MENU:'Roghchlár',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // US English (en-rUS)	
    enrUS: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT Collection',
        MAIN_TI_NFT_COLLECTION: 'NFT Collection',
        MAIN_NFT_MY_NFT: 'My NFTs',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Trending Collections',
        MAIN_NFT_ALL_COLLECTIONS: 'All Collections',
        MAIN_NFT_OWNED_BY: 'Owned by',
        MAIN_NFT_PRICE: 'Price',
        MAIN_NFT_HIGHEST_BID: 'Highest bid',
        MAIN_NFT_ADDRESS: 'Address',
        MAIN_NFT_MY_LIBRARY: 'My Library',
        MAIN_NFT_SIGN_IN: 'Sign in',
        MAIN_NFT_FAVOURITES: 'Favorites',
        MAIN_NFT_PLAY_ALL: 'Play All',
        MAIN_NFT_SIGNED_IN: 'Sign in',
        MAIN_MSG_NFT_NO_INTERNET: 'No Internet connection, content could not be loaded.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Server connection error, content could not be loaded.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Your browser cannot play the provided video file.',
        MAIN_BUTTON_CLOSE: 'Close',
        MAIN_BUTTON_SIGN_OUT: 'Sign out',
        MAIN_PANEL_VERSION_COLON :'Version:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Argentinian Spanish (eas)	
    eas: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Colección de NFT',
        MAIN_TI_NFT_COLLECTION: 'Colección de NFT',
        MAIN_NFT_MY_NFT: 'Mis NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Colecciones en tendencias',
        MAIN_NFT_ALL_COLLECTIONS: 'Todas las colecciones',
        MAIN_NFT_OWNED_BY: 'Propiedad de',
        MAIN_NFT_PRICE: 'Precio',
        MAIN_NFT_HIGHEST_BID: 'Oferta más alta',
        MAIN_NFT_ADDRESS: 'Dirección',
        MAIN_NFT_MY_LIBRARY: 'Mi biblioteca',
        MAIN_NFT_SIGN_IN: 'Iniciar sesión',
        MAIN_NFT_FAVOURITES: 'Favoritos',
        MAIN_NFT_PLAY_ALL: 'Reproduzir tudo',
        MAIN_NFT_SIGNED_IN: 'Iniciar sesión',
        MAIN_MSG_NFT_NO_INTERNET: 'No hay conexión a Internet; no se pudo cargar el contenido.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Error de conexión de servidor; no se pudo cargar el contenido.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Su navegador no puede reproducir el archivo de video proporcionado.',
        MAIN_BUTTON_CLOSE: 'Cerrar',
        MAIN_BUTTON_SIGN_OUT: 'Cerrar sesión',
        MAIN_PANEL_VERSION_COLON :'Versión:',
        MAIN_MENU:'Menú',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Brazilian Portuguese (bpt)	
    bpt: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Coleção de NFT',
        MAIN_TI_NFT_COLLECTION: 'Coleção de NFT',
        MAIN_NFT_MY_NFT: 'Meus NFTs',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Coleções em alta',
        MAIN_NFT_ALL_COLLECTIONS: 'Todas as coleções',
        MAIN_NFT_OWNED_BY: 'Propriedade de',
        MAIN_NFT_PRICE: 'Preço',
        MAIN_NFT_HIGHEST_BID: 'Proposta mais alta',
        MAIN_NFT_ADDRESS: 'Endereço',
        MAIN_NFT_MY_LIBRARY: 'Minha biblioteca',
        MAIN_NFT_SIGN_IN: 'Login',
        MAIN_NFT_FAVOURITES: 'Favoritos',
        MAIN_NFT_PLAY_ALL: 'Reproduzir tudo',
        MAIN_NFT_SIGNED_IN: 'Login',
        MAIN_MSG_NFT_NO_INTERNET: 'Sem conexão com a Internet. Não foi possível carregar o conteúdo.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Erro de conexão com o servidor. Não foi possível carregar o conteúdo.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Seu navegador não pode reproduzir o arquivo de vídeo fornecido.',
        MAIN_BUTTON_CLOSE: 'Fechar',
        MAIN_BUTTON_SIGN_OUT: 'Logout',
        MAIN_PANEL_VERSION_COLON :'Versão:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Indonesian (in)	
    in: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Koleksi NFT',
        MAIN_TI_NFT_COLLECTION: 'Koleksi NFT',
        MAIN_NFT_MY_NFT: 'NFT Saya',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Koleksi Populer',
        MAIN_NFT_ALL_COLLECTIONS: 'Semua Koleksi',
        MAIN_NFT_OWNED_BY: 'Dimiliki oleh',
        MAIN_NFT_PRICE: 'Harga',
        MAIN_NFT_HIGHEST_BID: 'Tawaran tertinggi',
        MAIN_NFT_ADDRESS: 'Alamat',
        MAIN_NFT_MY_LIBRARY: 'Pustaka Saya',
        MAIN_NFT_SIGN_IN: 'Masuk',
        MAIN_NFT_FAVOURITES: 'Favorit',
        MAIN_NFT_PLAY_ALL: 'Putar semua',
        MAIN_NFT_SIGNED_IN: 'Masuk',
        MAIN_MSG_NFT_NO_INTERNET: 'Tidak ada koneksi internet. Konten tidak dapat dimuat.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Kesalahan koneksi server. Konten tidak dapat dimuat.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Browser Anda tidak dapat memutar file video yang tersedia.',
        MAIN_BUTTON_CLOSE: 'Tutup',
        MAIN_BUTTON_SIGN_OUT: 'Keluar',
        MAIN_PANEL_VERSION_COLON :'Versi:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Hebrew (he)	
    he: {
        MAIN_APP_NAME_NFT_COLLECTION: 'אוסף NFT',
        MAIN_TI_NFT_COLLECTION: 'אוסף NFT',
        MAIN_NFT_MY_NFT: `ה-NFT שלי`,
        MAIN_NFT_TRENDING_COLLECTIONS: 'אוספים מובילים',
        MAIN_NFT_ALL_COLLECTIONS: 'כל האוספים',
        MAIN_NFT_OWNED_BY: 'בבעלות',
        MAIN_NFT_PRICE: 'מחיר',
        MAIN_NFT_HIGHEST_BID: 'ההצעה הגבוהה ביותר',
        MAIN_NFT_ADDRESS: 'כתובת',
        MAIN_NFT_MY_LIBRARY: 'הספרייה שלי',
        MAIN_NFT_SIGN_IN: 'כניסה',
        MAIN_NFT_FAVOURITES: 'מועדפים',
        MAIN_NFT_PLAY_ALL: 'הקרן הכול',
        MAIN_NFT_SIGNED_IN: 'כניסה',
        MAIN_MSG_NFT_NO_INTERNET: 'אין חיבור אינטרנט, לא ניתן לטעון את התוכן.',
        MAIN_MSG_NFT_SERVER_ERROR: 'שגיאת חיבור שרת, לא ניתן לטעון את התוכן.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'הדפדפן אינו יכול להפעיל את קובץ הווידאו הנתון.',
        MAIN_BUTTON_CLOSE: 'סגור',
        MAIN_BUTTON_SIGN_OUT: 'התנתק',
        MAIN_PANEL_VERSION_COLON :'גרסה:',
        MAIN_MENU:'תפריט',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Macedonian (mk)	
    mk: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Збирка на NFT',
        MAIN_TI_NFT_COLLECTION: 'Збирка на NFT',
        MAIN_NFT_MY_NFT: 'Мои NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Колекции во тренд',
        MAIN_NFT_ALL_COLLECTIONS: 'Сите колекции',
        MAIN_NFT_OWNED_BY: 'Во сопственост на',
        MAIN_NFT_PRICE: 'Цена',
        MAIN_NFT_HIGHEST_BID: 'Највисока понуда',
        MAIN_NFT_ADDRESS: 'Адреса',
        MAIN_NFT_MY_LIBRARY: 'Моја библиотека',
        MAIN_NFT_SIGN_IN: 'Најавете се',
        MAIN_NFT_FAVOURITES: 'Омилени',
        MAIN_NFT_PLAY_ALL: 'Репродуцирај сè',
        MAIN_NFT_SIGNED_IN: 'Најавете се',
        MAIN_MSG_NFT_NO_INTERNET: 'Нема интернет-врска, содржината не може да се вчита.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Грешка при поврзувањето со серверот, содржината не може да се вчита.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Вашиот пребарувач не може да ја репродуцира дадената видеодатотека.',
        MAIN_BUTTON_CLOSE: 'Затвори',
        MAIN_BUTTON_SIGN_OUT: 'Одјавување',
        MAIN_PANEL_VERSION_COLON :'Верзија:',
        MAIN_MENU:'Мени',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Malay (ms)	
    ms: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Koleksi NFT',
        MAIN_TI_NFT_COLLECTION: 'Koleksi NFT',
        MAIN_NFT_MY_NFT: 'NFT Saya',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Koleksi Sohor Kini',
        MAIN_NFT_ALL_COLLECTIONS: 'Semua Koleksi',
        MAIN_NFT_OWNED_BY: 'Dimiliki oleh',
        MAIN_NFT_PRICE: 'Harga',
        MAIN_NFT_HIGHEST_BID: 'Bida tertinggi',
        MAIN_NFT_ADDRESS: 'Alamat',
        MAIN_NFT_MY_LIBRARY: 'Pustaka Saya',
        MAIN_NFT_SIGN_IN: 'Daftar masuk',
        MAIN_NFT_FAVOURITES: 'Kegemaran',
        MAIN_NFT_PLAY_ALL: 'Mainkan Semua',
        MAIN_NFT_SIGNED_IN: 'Daftar masuk',
        MAIN_MSG_NFT_NO_INTERNET: 'Tiada sambungan Internet, kandungan tidak dapat dimuatkan.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Ralat sambungan pelayan, kandungan tidak dapat dimuatkan.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Pelayar anda tidak dapat memainkan fail video yang disediakan.',
        MAIN_BUTTON_CLOSE: 'Tutup',
        MAIN_BUTTON_SIGN_OUT: 'Daftar keluar',
        MAIN_PANEL_VERSION_COLON :'Versi:',
        MAIN_MENU:'Menu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // US Spanish (es-rUS)	
    esrUS: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Colección de NFT',
        MAIN_TI_NFT_COLLECTION: 'Colección de NFT',
        MAIN_NFT_MY_NFT: 'Mis NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Colecciones en tendencias',
        MAIN_NFT_ALL_COLLECTIONS: 'Todas las colecciones',
        MAIN_NFT_OWNED_BY: 'Propiedad de',
        MAIN_NFT_PRICE: 'Precio',
        MAIN_NFT_HIGHEST_BID: 'Oferta más alta',
        MAIN_NFT_ADDRESS: 'Dirección',
        MAIN_NFT_MY_LIBRARY: 'Mi biblioteca',
        MAIN_NFT_SIGN_IN: 'Iniciar sesión',
        MAIN_NFT_FAVOURITES: 'Favoritos',
        MAIN_NFT_PLAY_ALL: 'Reproducir todo',
        MAIN_NFT_SIGNED_IN: 'Iniciar sesión',
        MAIN_MSG_NFT_NO_INTERNET: 'No hay conexión a Internet; no se pudo cargar el contenido.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Error de conexión de servidor; no se pudo cargar el contenido.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Su navegador no puede reproducir el archivo de video proporcionado.',
        MAIN_BUTTON_CLOSE: 'Cerrar',
        MAIN_BUTTON_SIGN_OUT: 'Cerrar sesión',
        MAIN_PANEL_VERSION_COLON :'Versión:',
        MAIN_MENU:'Menú',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Korean (ko)	
    ko: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT 컬렉션',
        MAIN_TI_NFT_COLLECTION: 'NFT 컬렉션',
        MAIN_NFT_MY_NFT: '나의 NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: '인기 컬렉션',
        MAIN_NFT_ALL_COLLECTIONS: '모든 컬렉션',
        MAIN_NFT_OWNED_BY: '소유자',
        MAIN_NFT_PRICE: '가격',
        MAIN_NFT_HIGHEST_BID: '최고 입찰',
        MAIN_NFT_ADDRESS: '주소',
        MAIN_NFT_MY_LIBRARY: '내 라이브러리',
        MAIN_NFT_SIGN_IN: '로그인',
        MAIN_NFT_FAVOURITES: '즐겨찾기',
        MAIN_NFT_PLAY_ALL: '모두 재생',
        MAIN_NFT_SIGNED_IN: '로그인',
        MAIN_MSG_NFT_NO_INTERNET: '인터넷에 연결되어 있지 않아 콘텐츠를 로드할 수 없습니다.',
        MAIN_MSG_NFT_SERVER_ERROR: '서버 연결 오류로 인해 콘텐츠를 로드할 수 없습니다.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: '브라우저에서 제공된 비디오 파일을 재생할 수 없습니다.',
        MAIN_BUTTON_CLOSE: '닫기',
        MAIN_BUTTON_SIGN_OUT: '로그아웃',
        MAIN_PANEL_VERSION_COLON :'버전:',
        MAIN_MENU:'메뉴',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Albanian (sq)	
    sq: {
        MAIN_APP_NAME_NFT_COLLECTION: 'Koleksioni NFT',
        MAIN_TI_NFT_COLLECTION: 'Koleksioni NFT',
        MAIN_NFT_MY_NFT: 'NFT-të e mia',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Koleksionet në tendencë',
        MAIN_NFT_ALL_COLLECTIONS: 'Të gjitha koleksionet',
        MAIN_NFT_OWNED_BY: 'Zotëruar nga',
        MAIN_NFT_PRICE: 'Çmimi',
        MAIN_NFT_HIGHEST_BID: 'Oferta më e lartë',
        MAIN_NFT_ADDRESS: 'Adresa',
        MAIN_NFT_MY_LIBRARY: 'Biblioteka ime',
        MAIN_NFT_SIGN_IN: 'Identifikohu',
        MAIN_NFT_FAVOURITES: 'Të preferuara',
        MAIN_NFT_PLAY_ALL: 'Luaj të gjitha',
        MAIN_NFT_SIGNED_IN: 'Identifikohu',
        MAIN_MSG_NFT_NO_INTERNET: 'Nuk ka lidhje interneti, përmbajtja nuk mund të ngarkohet.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Gabim me lidhjen e serverit, përmbajtja nuk mund të ngarkohet.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Shfletuesi yt nuk mund të luajë skedarin e ofruar video.',
        MAIN_BUTTON_CLOSE: 'Mbyll',
        MAIN_BUTTON_SIGN_OUT: 'Dil',
        MAIN_PANEL_VERSION_COLON :'Versioni:',
        MAIN_MENU:'Menyja',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Armenian (hy)	
    hy: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT հավաքածու',
        MAIN_TI_NFT_COLLECTION: 'NFT հավաքածու',
        MAIN_NFT_MY_NFT: 'Իմ NFT-ները',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Հանրահայտ հավաքածուներ',
        MAIN_NFT_ALL_COLLECTIONS: 'Բոլոր հավաքածուները',
        MAIN_NFT_OWNED_BY: 'Սեփականատեր',
        MAIN_NFT_PRICE: 'Գին',
        MAIN_NFT_HIGHEST_BID: 'Ամենաբարձր առաջարկ',
        MAIN_NFT_ADDRESS: 'Հասցե',
        MAIN_NFT_MY_LIBRARY: 'Իմ դարանը',
        MAIN_NFT_SIGN_IN: 'Մուտք գործել',
        MAIN_NFT_FAVOURITES: 'Նախընտրելիներ',
        MAIN_NFT_PLAY_ALL: 'Վերարտադրել բոլորը',
        MAIN_NFT_SIGNED_IN: 'Մուտք գործել',
        MAIN_MSG_NFT_NO_INTERNET: 'Ինտերնետ կապ չկա, չհաջողվեց բեռնել բովանդակությունը։',
        MAIN_MSG_NFT_SERVER_ERROR: 'Սերվերի կապակցման սխալ, չհաջողվեց բեռնել բովանդակությունը։',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Ձեր զննիչը չի կարող նվագարկել տվյալ վիդեո ֆայլը։',
        MAIN_BUTTON_CLOSE: 'Փակել',
        MAIN_BUTTON_SIGN_OUT: 'Դուրս գալ',
        MAIN_PANEL_VERSION_COLON :'Տարբերակ.',
        MAIN_MENU:'Ընտրացանկ',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Azerbaijani (az)	
    az: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT Kolleksiyası',
        MAIN_TI_NFT_COLLECTION: 'NFT Kolleksiyası',
        MAIN_NFT_MY_NFT: 'NFT-lərim',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Trenddə olan Kolleksiyalar',
        MAIN_NFT_ALL_COLLECTIONS: 'Bütün Kolleksiyalar',
        MAIN_NFT_OWNED_BY: 'Sahibi',
        MAIN_NFT_PRICE: 'Qiymət',
        MAIN_NFT_HIGHEST_BID: 'Ən yüksək təklif',
        MAIN_NFT_ADDRESS: 'Ünvan',
        MAIN_NFT_MY_LIBRARY: 'Kitabxanam',
        MAIN_NFT_SIGN_IN: 'Daxil ol',
        MAIN_NFT_FAVOURITES: 'Sevimlilər',
        MAIN_NFT_PLAY_ALL: 'Hamısını çaldır',
        MAIN_NFT_SIGNED_IN: 'Daxil ol',
        MAIN_MSG_NFT_NO_INTERNET: 'İnternet bağlantısı yoxdur, məzmunu yükləmək alınmadı.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Server bağlantısında xəta var, məzmunu yükləmək mümkün olmadı.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Brauzeriniz təqdim olunan video faylını göstərə bilmir.',
        MAIN_BUTTON_CLOSE: 'Bağla',
        MAIN_BUTTON_SIGN_OUT: 'Çıxış',
        MAIN_PANEL_VERSION_COLON :'Versiyası:',
        MAIN_MENU:'Menyu',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Georgian (ka)	
    ka: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT-ს კოლექცია',
        MAIN_TI_NFT_COLLECTION: 'NFT-ს კოლექცია',
        MAIN_NFT_MY_NFT: 'ჩემი NFT-ები',
        MAIN_NFT_TRENDING_COLLECTIONS: 'ტრენდული კოლექციები',
        MAIN_NFT_ALL_COLLECTIONS: 'ყველა კოლექცია',
        MAIN_NFT_OWNED_BY: 'მფლობელი:',
        MAIN_NFT_PRICE: 'ფასი',
        MAIN_NFT_HIGHEST_BID: 'უმაღლესი შეთავაზებული ფასი',
        MAIN_NFT_ADDRESS: 'მისამართი',
        MAIN_NFT_MY_LIBRARY: 'ჩემი ბიბლიოთეკა',
        MAIN_NFT_SIGN_IN: 'შესვლა',
        MAIN_NFT_FAVOURITES: 'რჩეულები',
        MAIN_NFT_PLAY_ALL: 'ყველას დაკვრა',
        MAIN_NFT_SIGNED_IN: 'შესვლა',
        MAIN_MSG_NFT_NO_INTERNET: 'ინტერნეტ-კავშირი არ არის, შიგთავსის ჩატვირთვა შეუძლებელია.',
        MAIN_MSG_NFT_SERVER_ERROR: 'სერვერთან კავშირის შეცდომაა, შიგთავსის ჩატვირთვა შეუძლებელია.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'თქვენს ბრაუზერს მოწოდებული ვიდეო ფაილის დაკვრა არ შეუძლია.',
        MAIN_BUTTON_CLOSE: 'დახურვა',
        MAIN_BUTTON_SIGN_OUT: 'გამოსვლა',
        MAIN_PANEL_VERSION_COLON :'ვერსია:',
        MAIN_MENU:'მენიუ',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Mongolian (mn)	
    mn: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT Цуглуулга',
        MAIN_TI_NFT_COLLECTION: 'NFT Цуглуулга',
        MAIN_NFT_MY_NFT: 'Миний NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Тренд болж буй цувралууд',
        MAIN_NFT_ALL_COLLECTIONS: 'Бүх цувралууд',
        MAIN_NFT_OWNED_BY: 'Эзэмшигч',
        MAIN_NFT_PRICE: 'Үнэ',
        MAIN_NFT_HIGHEST_BID: 'Хамгийн өндөр санал болгосон үнэ',
        MAIN_NFT_ADDRESS: 'Хаяг',
        MAIN_NFT_MY_LIBRARY: 'Миний Сан',
        MAIN_NFT_SIGN_IN: 'Нэвтрэх',
        MAIN_NFT_FAVOURITES: 'Дуртай зүйлс',
        MAIN_NFT_PLAY_ALL: 'Бүгдийг тоглуулах',
        MAIN_NFT_SIGNED_IN: 'Нэвтрэх',
        MAIN_MSG_NFT_NO_INTERNET: 'Интернэт холболт байхгүй байна, контентыг ачаалж чадсангүй.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Серверийн холболтын алдаа, контентыг ачаалж чадсангүй.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Таны хөтөч өгөгдсөн видео файлыг тоглуулах боломжгүй.',
        MAIN_BUTTON_CLOSE: 'Хаах',
        MAIN_BUTTON_SIGN_OUT: 'Гарах',
        MAIN_PANEL_VERSION_COLON :'Хувилбар:',
        MAIN_MENU:'Цэс',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Icelandic (is)	
    is: {
        MAIN_APP_NAME_NFT_COLLECTION: 'NFT safn',
        MAIN_TI_NFT_COLLECTION: 'NFT safn',
        MAIN_NFT_MY_NFT: 'Mitt NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Vinsæl söfn',
        MAIN_NFT_ALL_COLLECTIONS: 'Öll söfn',
        MAIN_NFT_OWNED_BY: 'Í eigu',
        MAIN_NFT_PRICE: 'Verð',
        MAIN_NFT_HIGHEST_BID: 'Hæsta boð',
        MAIN_NFT_ADDRESS: 'Heimilisfang',
        MAIN_NFT_MY_LIBRARY: 'Bókasafnið mitt',
        MAIN_NFT_SIGN_IN: 'Skrá inn',
        MAIN_NFT_FAVOURITES: 'Uppáhald',
        MAIN_NFT_PLAY_ALL: 'Spila allt',
        MAIN_NFT_SIGNED_IN: 'Skrá inn',
        MAIN_MSG_NFT_NO_INTERNET: 'Engin nettenging, ekki var hægt að hlaða efni.',
        MAIN_MSG_NFT_SERVER_ERROR: 'Villa við tengingu netþjóns, ekki var hægt að hlaða efni.',
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'Vafrinn þinn getur ekki spilað meðfylgjandi myndskrá.',
        MAIN_BUTTON_CLOSE: 'Loka',
        MAIN_BUTTON_SIGN_OUT: 'Útskráning',
        MAIN_PANEL_VERSION_COLON :'Útgáfa:',
        MAIN_MENU:'Valmynd',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    },
    // Catalan (ca)
    ca: {
        MAIN_APP_NAME_NFT_COLLECTION: `Col·lecció d'NFT`,
        MAIN_TI_NFT_COLLECTION: `Col·lecció d'NFT`,
        MAIN_NFT_MY_NFT: 'Els meus NFT',
        MAIN_NFT_TRENDING_COLLECTIONS: 'Col·leccions populars',
        MAIN_NFT_ALL_COLLECTIONS: 'Totes les col·leccions',
        MAIN_NFT_OWNED_BY: 'Propietari',
        MAIN_NFT_PRICE: 'Preu',
        MAIN_NFT_HIGHEST_BID: 'Oferta més alta',
        MAIN_NFT_ADDRESS: 'Adreça',
        MAIN_NFT_MY_LIBRARY: 'La meva biblioteca',
        MAIN_NFT_SIGN_IN: 'Inicia la sessió',
        MAIN_NFT_FAVOURITES: 'Preferits',
        MAIN_NFT_PLAY_ALL: 'Reprodueix-ho tot',
        MAIN_NFT_SIGNED_IN: 'Inicia la sessió',
        MAIN_MSG_NFT_NO_INTERNET: `No hi ha connexió a Internet i el contingut no s'ha pogut carregar.`,
        MAIN_MSG_NFT_SERVER_ERROR: `S'ha produït un error de connexió amb el servidor i el contingut no s'ha pogut carregar.`,
        MAIN_MSG_NFT_CANNOT_PLAY_FILE: 'El navegador no pot reproduir aquest fitxer de vídeo.',
        MAIN_BUTTON_CLOSE: 'Tanca',
        MAIN_BUTTON_SIGN_OUT: 'Tanca la sessió',
        MAIN_PANEL_VERSION_COLON :'Versió:',
        MAIN_MENU:'Menú',
        rarible: 'Rarible',
        enlarge: 'Enlarge',
    }
};

export default translations;