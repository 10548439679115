import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import {
  useAccount,
  useDisconnect
} from "wagmi";

import { useEffect } from "react";
import "../css/signIn.css";
import metamask from "../images/metamask.svg";
import coinbase from "../images/coinbase.svg";
import { useNavigate } from 'react-router-dom';
import {
  useFocusable,
  FocusContext
} from "@noriginmedia/norigin-spatial-navigation";
import translations from "./translations";

function WalletsWrapper(props) {
  let selectedLanguage = localStorage.getItem("selected-language");
  if (selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];
  // if(!navigator.onLine){

  //   return(
  //     <Container style={{"textAlign":"center","marginTop":"20%","color":"wheat","fontSize":"x-large"}}>

  //         <span>No Internet connection, content could not be loaded</span>

  //     </Container>
  //    );
  //  }
  const { ref, focusKey, focusSelf } = useFocusable({ isFocusBoundary: true, focusKey: 'sign-in' });
  const storeAddress = (address) => {
    localStorage.setItem("metaMaskAccount", address);
    props.runParentUseEffect();
  };
  useEffect(() => {
    localStorage.setItem("shouldGoBack",false);
    localStorage.setItem("isDashboard",false);
    console.log("isDashboard",localStorage.getItem("isDashboard"));
    console.log("shouldGoBack",localStorage.getItem("shouldGoBack"));
    focusSelf();
    return () => {
      localStorage.setItem("shouldGoBack",true);
      localStorage.setItem("isDashboard",true);
    }
  }, [])
  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref}>
        <Container>
          <Row className="mt-sm-4 ms-sm-2">
            <Col className="signIn-title ">{translatedStrings?.MAIN_NFT_SIGN_IN}</Col>
          </Row>
          <Wallet walletName="Metamask" walletImage={metamask} storeAddress={storeAddress} onHide={() => { props.onHide(); }} />
          <Wallet walletName="Coinbase" walletImage={coinbase} storeAddress={storeAddress} onHide={() => { props.onHide(); }} />
          {/* <Wallet walletName="demo" walletImage={metamask} storeAddress={storeAddress} onHide={() => { props.onHide(); }} /> */}
          <Row className="mt-sm-4 ms-sm-3 mb-sm-4">
            <Col sm={4} className="custom-center offset-8">
              {String(localStorage.getItem("metaMaskAccount")) != "null" && <SignOut onHide={() => { props.onHide(); }} storeAddress={storeAddress} />}
              <Close onHide={() => { props.onHide(); }} />
            </Col>
          </Row>
        </Container>
      </div>
    </FocusContext.Provider>
  );
}

export function SignIn(props) {
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <div style={{ background: "#404040 0% 0% no-repeat padding-box" }}>
        <WalletsWrapper
          onHide={() => {
            props.runSetFocusUseEffect();
            props.onHide();
          }}
          runParentUseEffect={() => {
            props.runParentUseEffect();
          }}
        />
      </div>
    </Modal>
  );
}

function Wallet(props) {
  let selectedLanguage = localStorage.getItem("selected-language");
  if (selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];
  const navigate = useNavigate();
  const { ref, focused } = useFocusable({
    onEnterPress: () => {
      switch (props.walletName) {
        case "Metamask":
          localStorage.setItem('signedIn', 'MetaMask');
          navigate('/qr-code/0');
          break;
        case "Coinbase":
          localStorage.setItem('signedIn', 'Coinbase');
          navigate('/qr-code/1');
          break;
        case "demo":
          props.storeAddress("0x3B86796FD27f5E4794bdb4F2b12b99bFFb5B68f9");
          localStorage.setItem('signedIn', 'demo');
          props.onHide();
          break;
        default:
          console.log("default selected");
      }
    }
  });
  return (
    <Row className="mt-sm-5 ms-sm-1" style={{ height: "9.50vh" }}>
      <Col sm={2} className="custom-center">
        <img
          ref={ref}
          src={props.walletImage}
          className={focused ? "signIn-text-button-focus" : "signIn-text-button"}
        ></img>
      </Col>
      <Col sm="auto" style={{ display: "flex", alignItems: "center" }}>
        <div
          className={focused ? "signIn-w-text" : "signIn-b-text"}
        >
          {props.walletName}
        </div>
      </Col>
      <Col sm="auto" className="custom-center offset-2"></Col>
      <Col sm="auto" style={{ display: "flex", alignItems: "center" }}>
        <div
          className={"signedIn"}
        >
          {String(localStorage.getItem("metaMaskAccount")) !== "null" && String(localStorage.getItem("signedIn")) === props.walletName
            ? <>{translatedStrings?.MAIN_NFT_SIGNED_IN}</>
            : ""}
        </div>
      </Col>
    </Row>
  )
}

function Close(props) {
  let selectedLanguage = localStorage.getItem("selected-language");
  if (selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];
  const { ref, focused } = useFocusable({ onEnterPress: () => { props.onHide(); } });
  return (
    <div
      ref={ref}
      className={"signIn-close custom-center " + (focused ? "signIn-close-focus" : "")}
    >
      {translatedStrings?.MAIN_BUTTON_CLOSE}
    </div>
  )
}

function SignOut(props) {

  const sendNftStatus = (sign_on_status,wallet_selected) => {
    try {
      window.JavaFun.nftStatus(sign_on_status,wallet_selected);
    }
    catch(error) {
      console.log(error);
    }
  }

  let selectedLanguage = localStorage.getItem("selected-language");
  if (selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { ref, focused } = useFocusable({
    onEnterPress: () => {
      sendNftStatus("2",0);
        if (isConnected) {
        disconnect();
        props.storeAddress('null');
        props.onHide();
      }
      else {
        props.storeAddress('null');
        props.onHide();
      }
    }
  });
  return (
    <>
      <div
        ref={ref}
        className={"signIn-close custom-center " + (focused ? "signIn-close-focus" : "")}
      >
        {translatedStrings?.MAIN_BUTTON_SIGN_OUT}
      </div></>
  )
}
