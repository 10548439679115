import '../../css/imageComponentsCss/myCollectionImage.css';
import myCollectionVideoPlaceholder from '../../images/myCollectionVideoPlaceholder.svg';

export function MyCollectionImage(props) {
    return (<img
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src=myCollectionVideoPlaceholder;
    }}
      className={((process.env.REACT_APP_SERVER_TYPE == "prodAndroid" || process.env.REACT_APP_SERVER_TYPE == "accAndroid")?"skeleton-android":"skeleton-linux")}
      style={{objectFit:'cover'}}
       src={props.item} />
    );
}
