import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { polygon, mainnet } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import { HashRouter, Routes, Route } from 'react-router-dom';
import { NftCollections } from "./NftCollections";
import { MyNfts } from './MyNfts';
import { MarketPlace } from './MarketPlace';
import { CollectorsNFTs } from './CollectorsNFTs';
import { Nft } from './Nft';
import { NftFullscreen } from './NftFullscreen';
import { SlideShow } from './SlideShow';
import { QrCode } from './QrCode';
import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

// import useTranslation from '../customHooks/useTranslation';
// import TranslationContext from '../contexts/translationContext';

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet,polygon],
    [alchemyProvider({ apiKey: 'yourAlchemyApiKey' }), publicProvider()],
  )

// Set up wagmi config
const config = createConfig({
    autoConnect: true,
    connectors: [
      new WalletConnectConnector({
        chains,
        options: {
          projectId: 'a13c24fa11d2835b5324c3fffc34694f',
        },
      }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'wagmi',
        },
      })
    ],
    publicClient,
    webSocketPublicClient,
  })

  function getLanguageCode() {
    try {
      localStorage.setItem("selected-language",window.JavaFun.getLanguageCode()); 
    }
    catch(error) {
      console.log(error);
      localStorage.setItem("selected-language","en"); 
    }
  }

export default function App() {
  const [isFetching,setIsFetching] = useState(true);
  useEffect(() => {
    getLanguageCode();
    setIsFetching(false);
  },[]);
  if(isFetching) {
    return (
      <div style={{"textAlign": "center" , "marginTop": "25%"}}>
     {/* <Spinner animation="border" role="status" variant="light">
                    <span className="visually-hidden">Loading...</span>
                </Spinner> */}
    </div>
    )
  }
  else {
    return (
      <WagmiConfig config={config}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<NftCollections />}>
              <Route index element={<MyNfts />} />
              <Route path="mynfts/:id" element={<MyNfts />} />
              <Route path="marketplace/:id" element={<MarketPlace />} />
            </Route>
            <Route path="CollectorsNFTs/:id/:name" element={<CollectorsNFTs />} />
            <Route path="nft/:id" element={<Nft />} />
            <Route path="nftFullscreen" element={<NftFullscreen />} />
            <Route path="slideShow" element={<SlideShow />} />
            <Route path="qr-code/:id" element={<QrCode />} />
          </Routes>
        </HashRouter>
      </WagmiConfig>
    );
  }
}

