import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useEffect, useState } from "react";
import menu_black from "../images/menu_black_24dp.svg";
import menu_white from "../images/menu_white_24dp.svg";
import "../css/menu.css";
import {
  useFocusable,
  FocusContext
} from "@noriginmedia/norigin-spatial-navigation";
import translations from "./translations";

export default function Menu() {
  const [isShow, setIsShow] = useState(false);
  const {
    ref,
    focused,
    setFocus
  } = useFocusable({
    focusKey: 'menu',
    onEnterRelease: () => {
      setIsShow(true);
    }
  });
  useEffect(() => {
    if (!isShow) {
      setFocus('menu');
    }
  }, [isShow]);
  return (
    <>
      <Col sm="auto">
        <div
          ref={ref}
          className={"menu " + (focused ? "menu-focus" : "")}
        >
          <img
            style={{ height: "67.5%", width: "67.5%" }}
            src={focused ? menu_black : menu_white}
          />
        </div>
      </Col>
      {isShow && <SideBar setIsShow={setIsShow} isShow={isShow} />}
    </>
  );
}

function SideBar({ setIsShow, isShow }) {
  let selectedLanguage = localStorage.getItem("selected-language");
  if (selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];
  useEffect(() => {
    window.closeReactMenu = () => {
      setIsShow(false);
    };

    return () => {
      window.closeReactMenu = null;
      localStorage.setItem("shouldGoBack",false);
      localStorage.setItem("isDashboard",true);
      localStorage.setItem("isMenuOpen",false);
      console.log("Menu Closed");
    }
  },[])
  const version = translatedStrings?.MAIN_PANEL_VERSION_COLON + process.env.REACT_APP_VERSION;
  const {
    ref,
    focusKey,
    focusSelf
  } = useFocusable({
    focusKey: 'side-bar',
    isFocusBoundary: true
  });
  return (
    <FocusContext.Provider value={focusKey}>
      <Offcanvas
      style={{backgroundColor:'#212121'}}
        ref={ref}
        show={isShow}
        onEntered={() => {
          localStorage.setItem("shouldGoBack",false);
          localStorage.setItem("isDashboard",false);
          localStorage.setItem("isMenuOpen",true);
          focusSelf();
        }}
      >
        <div style={{display:"flex",height:'15%'}}>
         <div className="custom-center" style={{
          height:'100%',
          marginLeft:'4%'
         }}>
         <img
          style={{height:'40%'}}
            src={menu_white}
          />
         </div>
        <div style={{color:"white",
            padding:'8% 0% 10% 2%',
            font: "normal normal normal 21px/21px Roboto"
            }}>
            {translatedStrings?.MAIN_MENU}
        </div>
        </div>
          <div style={{ position: "relative", width: '100%', height: '100%', flexDirection: 'column', display:'flex' }}>
            <SideBarButton buttonNameTransl={translatedStrings?.MAIN_BUTTON_CLOSE} buttonName={"Close"} setIsShow={setIsShow} />
            <SideBarButton buttonNameTransl={version} buttonName={version} setIsShow={setIsShow} />
          </div>
      </Offcanvas>
    </FocusContext.Provider>
  )
}

function SideBarButton({ buttonName, setIsShow, buttonNameTransl }) {
  const { ref, focused } = useFocusable({
    onEnterRelease: () => {
      if (buttonName == "Close") {
        localStorage.setItem("shouldGoBack",false);
        localStorage.setItem("isDashboard",true);
        localStorage.setItem("isMenuOpen",false);
        setIsShow(false);
      }
      else {
        console.log("window", window);
      }
    }
  });
  return (
    <div ref={ref} style={{
      color:focused?'#050505':'#F5F5F5',
      backgroundColor:focused?'#F5F5F5':'#212121',
      padding:'5%',
      font: 'normal normal normal 18px/21px Roboto',
      opacity:focused?'1':'0.8'
    }}>
      {buttonNameTransl}
    </div>
  )
}


