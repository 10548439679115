import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useState, useMemo, useRef, useEffect } from "react";
import nft_not_available from "../images/nft_not_available.png";
import Path_22_red from "../images/Path_22_red.svg";
import { getUserItems } from "../api/FileManagementApi";
import { useNavigate } from "react-router-dom";
import {
  useFocusable,
  FocusContext,
} from "@noriginmedia/norigin-spatial-navigation";
import translations from "./translations";

export function Favourites() {
  const [stateObject, setStateObject] = useState(0);

  useEffect(() => {
      (async function () {
        try {
          let res = await getUserItems({});
        } catch (err) {
          console.log("err", err);
              setStateObject("Error");
        }
      })();
   
  }, []);
  let selectedLanguage = localStorage.getItem("selected-language");
  if(selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
    const translatedStrings = translations[selectedLanguage];
  const collectionItems = useRef([]);
  const [windowState, setWindowState] = useState(0);

  useMemo(() => {
    try {
      var existingFav = localStorage.getItem("favourites");
      collectionItems.current = existingFav ? JSON.parse(existingFav) : [];
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  let cardsCount = [0, 1, 2, 3, 4, 5, 6];
  let length = collectionItems.current.length;
  if (length < 7) {
    for (let i = 1; i <= 7 - length; i++) {
      cardsCount.pop();
    }
  }

  if(collectionItems.current.length == 0 || stateObject == "Error") {
    return(
      <></>
    )
  }

  return (
    <>
      <Row className="custom-favourites-title mt-xxl-2 mt-xl-2 mt-sm-1">
        <Col>{translatedStrings?.MAIN_NFT_FAVOURITES}</Col>
      </Row>
      <Row className="mt-xxl-0 mt-xl-0 mt-sm-0">
        <BlocksWrapper
        cardsCount={cardsCount}
        collectionItems={collectionItems}
        windowState={windowState}
        setWindowState={setWindowState}/>
      </Row>
    </>
  );
}

function BlocksWrapper({cardsCount, collectionItems, windowState, setWindowState}) {
  const { ref, focusKey } = useFocusable({ focusKey: "favourites" });
  return (
    <FocusContext.Provider value={focusKey}>
          <div className="custom-wrapper" id="wrapper" ref={ref}>
            {cardsCount.map((index) => {
              if (collectionItems.current[windowState + index].img) {
                return (
                  <Block
                    item={collectionItems.current[windowState + index]}
                    index={index}
                    rowRef={ref}
                    cardsCount = {cardsCount.length}
                    collections={collectionItems}
                    windowState={windowState}
                    setWindowState={setWindowState}
                  />
                );
              }
            })}
          </div>
        </FocusContext.Provider>
  )
}

function Block({ item, index, rowRef, cardsCount, collections, windowState, setWindowState }) {
  let selectedLanguage = localStorage.getItem("selected-language");
  if(selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];
  const navigate = useNavigate();
  const { ref, focused } = useFocusable({
    onFocus: () => {
      //logic to align the collections properly in the viewport.
      let cardLeftDistance =
        rowRef.current.childNodes[index].getBoundingClientRect().left;
      //horizontal movement
      if (
        cardLeftDistance > (50 / 100) * window.innerWidth ||
        cardLeftDistance < (30 / 100) * window.innerWidth
      ) {
        rowRef.current.childNodes[index].scrollIntoView({
          block: "nearest",
          inline: "center",
        });
      }
    },
    onArrowPress:(event) => {
      let cardLeftDistance = rowRef.current.childNodes[index].getBoundingClientRect().left;
      if((event == "right") && (index == cardsCount - 1)) {
        if(!((collections.current.length - 1) == (windowState + cardsCount - 1))){
          setWindowState(windowState + 1);
      }
      } 
      else if((event == "left") && (cardLeftDistance < ((10/100)*window.innerWidth))) {
        if(!(windowState == 0)) {
            setWindowState(windowState - 1);
        }
    }
    },
    onEnterPress:() => {
      navigate("/nft/" + item.address);
    }
  });
  
  return (
    <div
      ref={ref}
      className={
        "custom-favourites-card " +
        (focused ? "favourites-focusable-focus" : "")
      }
    >
      <div className="favouriteImage">
        {item.imgType == "VIDEO" ? (
          <video
            autoPlay
            muted
            loop
            className="image"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = nft_not_available;
            }}
          >
            <source src={item.img} type="video/webm" />
            <source src={item.img} type="video/mp4" />
            <p>{translatedStrings?.MAIN_MSG_NFT_CANNOT_PLAY_FILE}</p>
          </video>
        ) : item.imgType == "IMAGE" ? (
          <img
            className="image"
            loading="lazy"
            src={item.img}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = nft_not_available;
            }}
            alt="image1"
          />
        ) : (
          ""
        )}
      </div>
      <div className="textAlignLeftName">
        {item.itemName.length > 25
          ? item.itemName.substring(0, 25) + "..."
          : item.itemName}
      </div>
      <div className="textAlignLeftFav">
        {item.price ? (
          <div className="textAlignLeftFav-div1">{translatedStrings?.MAIN_NFT_PRICE} {item.price} {item.currency}</div>
        ) : (
          <div className="textAlignLeftFav-div1">{translatedStrings?.MAIN_NFT_PRICE}</div>
        )}
        {item.highestBid ? (
          <div className="textAlignLeftFav-div1">
            {translatedStrings?.MAIN_NFT_HIGHEST_BID} {item.highestBid}
          </div>
        ) : (
          <div className="textAlignLeftFav-div1">{translatedStrings?.MAIN_NFT_HIGHEST_BID} </div>
        )}
      </div>
      <div className="favourites-like-icon custom-center">
        <img
          style={{ height: "39.176%", width: "44.411%" }}
          src={Path_22_red}
          alt="icon"
        />
      </div>
    </div>
  );
}
