import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './components/App';

import {
  init
} from "@noriginmedia/norigin-spatial-navigation";

init({
  debug: false,
  visualDebug: false
});
 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
);
