import Spinner from 'react-bootstrap/Spinner';
import { Buffer } from "buffer";
import {
  useAccount,
  useConnect,
  useDisconnect
} from 'wagmi';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from 'react';
window.Buffer = Buffer;

export function QrCode() {

  const sendNftStatus = (sign_on_status,wallet_selected) => {
    try {
      window.JavaFun.nftStatus(sign_on_status,wallet_selected);
    }
    catch(error) {
      console.log(error);
    }
  }

  const navigate = useNavigate();
  const { id } = useParams();
  const { address, isConnected:accountIsConnected } = useAccount()
  const { connect, connectors, isLoading:connectLoading, isSuccess: connectSuccess } = useConnect();
  const { disconnect, isSuccess: disconnectSuccess } = useDisconnect();

  //immediately after opening the QrCode page check if the user is connected or not.
  useEffect(()=>{
    let connector = connectors[id];
    if(!accountIsConnected) {
      console.log("User was not connected");
      connect({ connector });
    }
    else {
      disconnect();
    }

    //create timer for wallet connect. 
    let timerInterval;
    if(id == 0) {
       timerInterval = setInterval(()=>{
        try {
          // document.getElementsByTagName("wcm-modal")[0].shadowRoot.childNodes[3].childNodes[0].childNodes[2].childNodes[0].shadowRoot.childNodes[1].childNodes[0].childNodes[1].shadowRoot.childNodes[2].shadowRoot.childNodes[1].shadowRoot.childNodes[1].childNodes[6].click();
          //To not display Qr code footer.
          document.getElementsByTagName("wcm-modal")[0].shadowRoot.childNodes[3].childNodes[0].childNodes[2].childNodes[0].shadowRoot.childNodes[1].childNodes[0].childNodes[1].shadowRoot.childNodes[2].shadowRoot.childNodes[4].style.display = 'none';
          //To not display Qr code cross button.
          document.getElementsByTagName("wcm-modal")[0].shadowRoot.childNodes[3].childNodes[0].childNodes[1].shadowRoot.childNodes[2].childNodes[3].style.display = 'none';
        }
        catch(error) {
          console.log(error);
        }
      }, 500);
    }

    localStorage.setItem("shouldGoBack",true);
    localStorage.setItem("isDashboard",false);

    return () => {
      //clear timer which was created for wallet connect.
      if(id == 0) {
        clearInterval(timerInterval);
      }
      window.location.reload();
    }
  },[]);

  //after succesfully disconnecting, invoke connect().
  useEffect(()=>{
    if(disconnectSuccess) {
      let connector = connectors[id];
      console.log("Disconnected Sucessfully");
      localStorage.setItem("metaMaskAccount", 'null');
      id == 0 ? sendNftStatus("2",0) : sendNftStatus("2",1);
      connect({ connector });
    }
  },[disconnectSuccess]);

  useEffect(()=>{
    if(connectSuccess) {
      navigate(-1);
    }
  });


  if(connectLoading) {
    return (
      <div className="custom-center" style={{height:"100vh"}}>
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
      </div>
    )
  }

  if(connectSuccess) {
    localStorage.setItem("metaMaskAccount", address);
    id == 0 ? sendNftStatus("0",0) : sendNftStatus("0",1);
    return (
      <div className="custom-center" style={{height:"100vh",color:'#9bb878'}}>
            Account: {address} connected. Please go back to the dashboard.
      </div>
    )
  }

}
