import React, { useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Outlet } from 'react-router-dom';
import '../css/nftCollections.css';
import NavItems from '../components/NavItems';
import {
  useFocusable,
  FocusContext
} from "@noriginmedia/norigin-spatial-navigation";
import Menu from './Menu';
import translations from './translations';
import { useState } from 'react';

export function NftCollections() {
  const count = useRef(0);
  const { ref, focusKey } = useFocusable({
    focusKey: 'nav-items'
  });
  let selectedLanguage = localStorage.getItem("selected-language");
  console.log("Hello",selectedLanguage);
  if(selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];

  const [state,setState] = useState(JSON.parse(localStorage.getItem("navItemsSelectedState")));
  count.current = count.current + 1;
  return (
    <Container fluid className="ms-xxl-5 mt-xxl-4 ms-xl-4 mt-xl-4 ms-sm-3 mt-sm-4">
      <Row className="mt-xxl-5 mt-xl-4 mt-sm-0">
        <Menu />
        <Col style={{ display: 'flex', alignItems: 'center' }}><div className="page1-title">{translatedStrings?.MAIN_APP_NAME_NFT_COLLECTION}</div></Col>
      </Row>
      <Row className="mt-xxl-3 margin-top-540p"></Row>
      <FocusContext.Provider value={focusKey}>
        <Row className="mt-xxl-5 mt-xl-5 mt-sm-4" ref={ref}>
          <NavItems state={state} key={count.current}/>
        </Row>
      </FocusContext.Provider>
      <Outlet context={[state,setState]}/>
    </Container>
  )
}
