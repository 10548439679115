import Container from "react-bootstrap/esm/Container";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../css/nft.css';
import QRCode from "react-qr-code";
import { useEffect, useRef, useState } from "react";
import Path_22 from '../images/Path_22.svg';
import Path_22_red from '../images/Path_22_red.svg';
import zoom_in_black_24dp from '../images/zoom_in_black_24dp.svg';
import Spinner from 'react-bootstrap/Spinner';
import { getItemByItemid,getOwnershipByItemid } from '../api/FileManagementApi';
import nft_not_available from '../images/nft_not_available.png';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    useFocusable,
  } from "@noriginmedia/norigin-spatial-navigation";
import translations from "./translations";

export function Nft() {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];

    useEffect(()=>{
        localStorage.setItem("shouldGoBack",true);
        localStorage.setItem("isDashboard",false);
        console.log("isDashboard",localStorage.getItem("isDashboard"));
        console.log("shouldGoBack",localStorage.getItem("shouldGoBack"));
    },[])
    if(!navigator.onLine){

        return(
          <Container style={{"textAlign":"center","marginTop":"20%","color":"wheat","fontSize":"x-large"}}>
            
              <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>
            
          </Container>
         );
       }
    else {
        return (
            <Wrapper/>
        )
    }
}

function Wrapper() {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }

    const sendNftFavouriteStatus = (item_id,market_place,fav_state) => {
        try {
            window.JavaFun.nftFavouriteStatus(item_id,market_place,fav_state);
        }
        catch(error) {
            console.log(error);
        }
    }

    const translatedStrings = translations[selectedLanguage];
    const afterApiResponse = useRef(false);
    const [stateObject, setStateObject] = useState("Loading");
    const itemDetails = useRef([]);
    const ownership = useRef([]);
    const favouriteInitialState = useRef(false);
    const { id } = useParams()
    const localStorageSetImage = () => {
        if(itemDetails.current.meta.content.length > 1 && itemDetails.current.meta.content[1]["@type"] == "IMAGE"){
            localStorage.setItem("fullScreenImgType", "IMAGE");
            localStorage.setItem("fullScreenImg", itemDetails.current.meta.content[1].url);
        }
        else if(itemDetails.current.meta.content.length > 1 && itemDetails.current.meta.content[1]["@type"] == "VIDEO"){
            localStorage.setItem("fullScreenImgType", "VIDEO");
            localStorage.setItem("fullScreenImg", itemDetails.current.meta.content[1].url);
        }else if(itemDetails.current.meta.content.length == 1 && itemDetails.current.meta.content[0]["@type"] == "IMAGE"){
            localStorage.setItem("fullScreenImgType", "IMAGE");
            localStorage.setItem("fullScreenImg", itemDetails.current.meta.content[0].url);
        }else if(itemDetails.current.meta.content.length == 1 && itemDetails.current.meta.content[0]["@type"] == "VIDEO"){
            localStorage.setItem("fullScreenImgType", "VIDEO");
            localStorage.setItem("fullScreenImg", itemDetails.current.meta.content[0].url);
        }
        else if(itemDetails.current.meta.content.length > 1 && itemDetails.current.meta.content[1]["@type"] == "HTML" && itemDetails.current.meta.content[0]["@type"] == "IMAGE"){
            localStorage.setItem("fullScreenImgType", "IMAGE");
            localStorage.setItem("fullScreenImg", itemDetails.current.meta.content[0].url);
        }
        else if(itemDetails.current.meta.content.length > 1 && itemDetails.current.meta.content[1]["@type"] == "HTML" && itemDetails.current.meta.content[0]["@type"] == "VIDEO"){
            localStorage.setItem("fullScreenImgType", "VIDEO");
            localStorage.setItem("fullScreenImg", itemDetails.current.meta.content[0].url);
        }
      }
    let newId = id.slice(id.indexOf(":")+1,id.length)
    if(localStorage.getItem("apiType") == "testnet"){
        console.log("1");
        var url = "https://testnet.rarible.com/token/"+newId+"?tab=overview";
    }else{
        console.log("2");
        var url = "https://rarible.com/token/"+newId+"?tab=overview";
    }
    function updateFavourite(status) {
        let selectedLanguage = localStorage.getItem("selected-language");
        if(selectedLanguage == null || selectedLanguage == 'null') {
          selectedLanguage = 'en';
        }
        if(status){
            sendNftFavouriteStatus(itemDetails.current.id,"rarible","0");
            let contentLength = itemDetails.current.meta.content.length;
        var fav = {
            //video can be in 0 or 1 index only.
            img : (contentLength == 1 && itemDetails.current.meta.content[0]["@type"] == "VIDEO") ? itemDetails.current.meta.content[0].url : 
            (contentLength == 2 && itemDetails.current.meta.content[1]["@type"] == "VIDEO") ? itemDetails.current.meta.content[1].url :
            (contentLength == 2 || contentLength == 3 || contentLength == 4) ? itemDetails.current.meta.content[1].url : itemDetails.current.meta.content[0].url,
            imgType: (contentLength == 1 && itemDetails.current.meta.content[0]["@type"] == "VIDEO") ? "VIDEO" : 
            (contentLength == 2 && itemDetails.current.meta.content[1]["@type"] == "VIDEO") ? "VIDEO" : "IMAGE",
            itemName: itemDetails.current.meta.name,
            owner: itemDetails.current.lastSale ? itemDetails.current.lastSale.buyer : "",
            price : itemDetails.current.bestSellOrder ? itemDetails.current.bestSellOrder.makePrice : "",
            currency : (itemDetails.current.bestSellOrder&& itemDetails.current.bestSellOrder.take&& itemDetails.current.bestSellOrder.take.type) ? itemDetails.current.bestSellOrder.take.type["@type"] : "",
            highestBid : itemDetails.current.bestBidOrder ? itemDetails.current.bestBidOrder.takePrice : "",
            address : itemDetails.current.id
        }
        var existingFav = localStorage.getItem("favourites");
        var data = existingFav ? JSON.parse(existingFav) : [];
        data.push(fav)
        localStorage.setItem("favourites", JSON.stringify(data));
            }else{
            sendNftFavouriteStatus(itemDetails.current.id,"rarible","1");
        var existingFav = JSON.parse(localStorage.getItem("favourites"));
        if(existingFav.length == 1){
            localStorage.setItem("favourites", JSON.stringify([]));
        }else{
            existingFav.splice(existingFav.findIndex(x => x.address ===itemDetails.current.id), 1)
            localStorage.setItem("favourites", JSON.stringify(existingFav));
        }
            }
    }
    useEffect(() => {
        (async function () {
            try {
                itemDetails.current = await getItemByItemid({id:id});
                console.log("itemDetails",itemDetails.current);
                ownership.current = await getOwnershipByItemid({id:id});
                console.log("ownership",ownership.current);
                var local = localStorage.getItem("favourites")
                var index = local ? JSON.parse(local) : []
                if(index.findIndex(x => x.address === itemDetails.current.id) != -1){
                    favouriteInitialState.current = true;
                }
            //    console.log(stateObject);
               afterApiResponse.current = true;
               setStateObject("Not Loading");
            //    console.log(stateObject);
            }
            catch (err) {
                console.log('err', err);
                if(err.code == "ERR_NETWORK"){
                    setStateObject("Network lost");
                }else{
                    setStateObject("Server Error");
                }
            }
        })();
    }, [])
    if (stateObject === 'Loading') {
        return (
            <Container style={{"textAlign": "center" , "marginTop": "25%"}}>
                <Spinner animation="border" role="status" variant="light">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }
    if(stateObject == "Server Error") {
         translatedStrings = translations[selectedLanguage];
        return(
            <Container style={{"textAlign": "center" , "marginTop": "25%"}}>
            <Spinner animation="border" role="status" variant="light">
                <span className="visually-hidden">{translatedStrings?.MAIN_MSG_NFT_SERVER_ERROR}</span>
            </Spinner>
        </Container>
        )
    }
    else if(stateObject == "Network lost"){
        return (
            <Container style={{"textAlign":"center","marginTop":"9%","color":"wheat","fontSize":"x-large"}}>

            <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>

            </Container> 
        );
    }
    else{
    return (
        <Container fluid>
            <Row className="header-row">
                <Col style={{ display: 'flex' }}>
                    <div className="header-box"><img className="header-box-img" src={localStorage.getItem('collectionImg')}alt="image1"/></div>
                    <div className="header-title">{localStorage.getItem('collectionId')}</div>
                </Col>
            </Row>
            <Row>
                <Col sm='auto'>
                    <div className='body-card'>
                        <div className={"card-img-div "+((process.env.REACT_APP_SERVER_TYPE == "prodAndroid" || process.env.REACT_APP_SERVER_TYPE == "accAndroid")?"skeleton-android":"skeleton-linux")}>
                            {
                            (itemDetails.current.meta.content.length > 1 &&    
                                itemDetails.current.meta.content[1]["@type"] == "IMAGE")  ? (
                                    <img className="image" loading="lazy" src={
                                        itemDetails.current.meta.content.length == 4 ? itemDetails.current.meta.content[1].url :
                                        itemDetails.current.meta.content.length == 3 ? itemDetails.current.meta.content[1].url :
                                        itemDetails.current.meta.content.length == 2 ? itemDetails.current.meta.content[1].url :
                                        itemDetails.current.meta.content[0].url
                                    } 
                                        onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src=nft_not_available;
                                    }} alt="image1"/>
                            ):
                            (itemDetails.current.meta.content.length > 1 &&    
                            itemDetails.current.meta.content[1]["@type"] == "VIDEO")  ? (
                            <video autoPlay muted loop
                            className="MyCollectionImg"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = nft_not_available;
                            }}
                            >
                            <source src={itemDetails.current.meta.content[1].url} type='video/webm' />
                            <source src={itemDetails.current.meta.content[1].url} type='video/mp4' />
                            <p>{translatedStrings?.MAIN_MSG_NFT_CANNOT_PLAY_FILE}</p>
                          </video>
                          ) :
                            (itemDetails.current.meta.content.length == 1 && 
                            itemDetails.current.meta.content[0]["@type"] == "IMAGE" )? (
                                <img className="image" loading="lazy" src={
                                    itemDetails.current.meta.content[0].url
                                } 
                                    onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src=nft_not_available;
                                }} alt="image1"/>
                                ) : 
                                (itemDetails.current.meta.content.length == 1 && 
                                    itemDetails.current.meta.content[0]["@type"] == "VIDEO" ? (
                                    <video autoPlay muted loop
                                      className="MyCollectionImg"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = nft_not_available;
                                      }}
                                      >
                                      <source src={itemDetails.current.meta.content[0].url} type='video/webm' />
                                      <source src={itemDetails.current.meta.content[0].url} type='video/mp4' />
                                      <p>{translatedStrings?.MAIN_MSG_NFT_CANNOT_PLAY_FILE}</p>
                                    </video>
          
                                  ) :
                                  (itemDetails.current.meta.content.length > 1 &&    
                                    itemDetails.current.meta.content[1]["@type"] == "HTML" && itemDetails.current.meta.content[0]["@type"] == "IMAGE")  ? (
                                        <img className="image" loading="lazy" src={
                                            itemDetails.current.meta.content[0].url
                                        } 
                                            onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src=nft_not_available;
                                        }} alt="image1"/>
                                ):
                                (itemDetails.current.meta.content.length > 1 &&  
                                    itemDetails.current.meta.content[1]["@type"] == "HTML" &&  
                                    itemDetails.current.meta.content[0]["@type"] == "VIDEO")  ? (
                                    <video autoPlay muted loop
                                    className="MyCollectionImg"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = nft_not_available;
                                    }}
                                    >
                                    <source src={itemDetails.current.meta.content[0].url} type='video/webm' />
                                    <source src={itemDetails.current.meta.content[0].url} type='video/mp4' />
                                    <p>{translatedStrings?.MAIN_MSG_NFT_CANNOT_PLAY_FILE}</p>
                                  </video>
                                  ) : "" )
                                }
                        </div>
                        <Enlarge localStorageSetImage={localStorageSetImage}/>
                        <Favourite updateFavourite={updateFavourite} favouriteInitialState = {favouriteInitialState.current}/>
                    </div>
                </Col>
                <Col sm='auto' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ height: '57.5vh', width: '50.05vw', marginLeft:'5.612%' }}>
                        <div className="txt-1 ">{itemDetails.current.meta.name}</div>
                        <div className="txt-2 ">Owned By {ownership.current.ownerships.length != 0 ? ownership.current.ownerships[0].owner : ""}</div>
                        <div className="txt-3 ">{translatedStrings?.MAIN_NFT_PRICE} {(itemDetails.current.bestSellOrder&&itemDetails.current.bestSellOrder.makePrice) ? (itemDetails.current.bestSellOrder.makePrice.length > 6 ? itemDetails.current.bestSellOrder.makePrice.substring(0, 6) : itemDetails.current.bestSellOrder.makePrice) : ""}  { (itemDetails.current.bestSellOrder&& itemDetails.current.bestSellOrder.take&& itemDetails.current.bestSellOrder.take.type) ? itemDetails.current.bestSellOrder.take.type["@type"] : ""}</div>
                        <div className="txt-4 ">{translatedStrings?.MAIN_NFT_HIGHEST_BID} {itemDetails.current.bestBidOrder ? itemDetails.current.bestBidOrder.takePrice : ""}</div>
                        <div className="txt-5 ">{translatedStrings?.MAIN_NFT_ADDRESS}</div>
                        <div className="txt-6 ">{itemDetails.current.id}</div>
                        <div className="custom-center" style={{ width: '12.5vw', backgroundColor: 'white', position: 'relative', top: '23.5%',padding:"3px" }}>
                                <QRCode
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={url} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
    }
}

function Enlarge({localStorageSetImage}) {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];
    let navigate = useNavigate();
    const {ref,focused,focusSelf} = useFocusable({onEnterPress:()=>{
        navigate('/nftFullscreen');
        localStorageSetImage();
    }});
    useEffect(()=>{
        focusSelf();
    },[])
    return (
        <div ref={ref} tabIndex="-1"
        className={"custom-center " + "card-enlarge-div " + (focused ? 'focused' : 'not-focused')}>
        <img style={{width:"2.135vw",height:"3.79vh",marginRight:"0.7vw"}} src={zoom_in_black_24dp}/>{translatedStrings?.enlarge}</div>
    )
}

function Favourite({updateFavourite,favouriteInitialState}) {
    const [selectState, setSelectState] = useState(favouriteInitialState);
    const {ref,focused} = useFocusable({onEnterPress:()=>{
        updateFavourite(!selectState);
        setSelectState(!selectState);
    }})
    return (
        <div tabIndex='-1' ref={ref}
        className={"custom-center " + "card-favourite-div " + (focused ? 'focused' : 'not-focused')}>
        <img style={{ width: "2.343vw", height: "3.611vh" }} src={selectState ? Path_22_red : Path_22} />
    </div>
    )
}