import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import category_black_24dp from '../images/category_black_24dp.svg';
import category_white_24dp from '../images/category_white_24dp.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../css/navItems.css';
import {
    useFocusable
} from "@noriginmedia/norigin-spatial-navigation";
import translations from './translations';
export default function NavItems({state}) {
    return (
        <NavItemsWrapper NavItemsSelectedState={state} />
    )
}


function NavItemsWrapper({NavItemsSelectedState}) {
    let navigate = useNavigate();
    const onNavItemClick = (navItem) => {
        switch (navItem) {
            case 'Rarible':
                navigate('/marketplace/rarible');
                break;
            case 'My NFTs':
                navigate('/mynfts/myNft');
                break;
        }
    }
    const [selectedState, setSelectedState] = useState(NavItemsSelectedState);
    let selectedLanguage = localStorage.getItem("selected-language");
    if (selectedLanguage == null || selectedLanguage == 'null') {
        selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];
    return (
        <>
            <Col xxl={2} xl={2} sm={2}>
                <MyNfts translatedStrings={translatedStrings} selectedState={selectedState} setSelectedState={setSelectedState} onNavItemClick={() => { onNavItemClick('My NFTs'); }} navItemName={'My NFTs'} />
            </Col>
            <Col sm={10} xxl={10} xl={10}>
                <Stack direction="horizontal" className='ms-xxl-3 ms-xl-3 ms-sm-2 gap-xxl-4 gap-xl-4 gap-sm-3'>
                    <div className="custom-center">
                        <Rarible translatedStrings={translatedStrings} selectedState={selectedState} setSelectedState={setSelectedState} onNavItemClick={() => { onNavItemClick('Rarible'); }} navItemName={'Rarible'} />
                    </div>
                </Stack>
            </Col>
        </>
    )
}

function Rarible(props) {
    let selectedState;
    selectedState = props.selectedState[1]
    const { ref, focused } = useFocusable({
        onEnterPress: () => {
            switch (props.navItemName) {
                case "My NFTs":
                    localStorage.setItem("navItemsSelectedState",JSON.stringify([true,false,false,false]));
                    props.setSelectedState([true, false, false, false]);
                    console.log("Hello1");
                    break;
                case "Rarible":
                    localStorage.setItem("navItemsSelectedState",JSON.stringify([false,true,false,false]));
                    props.setSelectedState([false, true, false, false]);
                    console.log("Hello2");
                    break;
            }
            props.onNavItemClick();
        }
    });
    return (
        <div
            ref={ref}
            className={"custom-center " + "navitem-marketplace " + (focused ? 'navitems-focusable-focus' : selectedState ? "selected " : "not-selected ")}>
            {props.translatedStrings?.rarible}
        </div>
    )
}

function MyNfts(props) {
    let selectedState;
    selectedState = props.selectedState[0]
    const { ref, focused, focusSelf } = useFocusable({
        onEnterPress: () => {
            switch (props.navItemName) {
                case "My NFTs":
                    localStorage.setItem("navItemsSelectedState",JSON.stringify([true,false,false,false]));
                    props.setSelectedState([true, false, false, false]);
                    console.log("Hello3");
                    break;
                case "Rarible":
                    localStorage.setItem("navItemsSelectedState",JSON.stringify([false,true,false,false]));
                    props.setSelectedState([false, true, false, false]);
                    console.log("Hello4");
                    break;
            }
            props.onNavItemClick();
        },
        focusKey: 'My-NFTS'
    });
    useEffect(() => {
        if (props.navItemName == "My NFTs") {
            focusSelf();
        }
    }, []);
    if (props.navItemName == "My NFTs") {
        return (
            <div ref={ref}
                className={"custom-center " + "navitem-mynfts " + (focused ? 'navitems-focusable-focus' : selectedState ? "selected " : "not-selected ")}>
                <img style={{ width: '3.33vh', height: '3.33vh', opacity: '1', marginRight: '0.833vh' }}
                    src={focused ? category_black_24dp : selectedState ? category_black_24dp : category_white_24dp} />
                {props.translatedStrings?.MAIN_NFT_MY_NFT}
            </div>
        )
    }
}
