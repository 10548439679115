import {MyCollection} from './MyCollection';
import {Favourites} from './Favourites';
import Container from 'react-bootstrap/Container';
import translations from './translations';

export function MyNfts() {
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
    const translatedStrings = translations[selectedLanguage];
    if(!navigator.onLine){

        return(
          <Container style={{"textAlign":"center","marginTop":"20%","color":"wheat","fontSize":"x-large"}}>
            
              <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>
            
          </Container>
         );
       }
    return (
        <>
        <MyCollection/>
        <Favourites/>
        </>
    )
}