import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SignIn } from "./SignIn";
import { useEffect, useState, useRef, useCallback } from "react";
import { getUserItems } from "../api/FileManagementApi";
import "../css/myCollection.css";
import Container from 'react-bootstrap/Container';
import { MyCollectionImage } from "./imageComponents/MyCollectionImage";
import { useNavigate } from 'react-router-dom';
import {
  useFocusable,
  FocusContext
} from "@noriginmedia/norigin-spatial-navigation";
import translations from "./translations";
import videoPlaceHolder from '../images/myCollectionVideoPlaceholder.svg';
import { useOutletContext } from "react-router-dom";

export function MyCollection() {
  const [state,setState] = useOutletContext();
  useEffect(()=>{
    localStorage.setItem("shouldGoBack",false);
    localStorage.setItem("isDashboard",true);
    setState([true,false,false,false]);
    console.log("isDashboard",localStorage.getItem("isDashboard"));
    console.log("shouldGoBack",localStorage.getItem("shouldGoBack"));
return () => {
  localStorage.setItem("shouldGoBack",true);
  localStorage.setItem("isDashboard",false);
}
},[]);
    let selectedLanguage = localStorage.getItem("selected-language");
    if(selectedLanguage == null || selectedLanguage == 'null') {
      selectedLanguage = 'en';
    }
const translatedStrings = translations[selectedLanguage];
  const filterMyCollection = useCallback((item)=>{
    return (item.meta && item.meta.content && item.meta.content.length > 0 && item.meta.content[0].url)
  },[]);
  const {
    ref,
    focusKey,
    focusSelf
  } = useFocusable({
    focusKey: 'my-collection'
  });
  const [useEffectState, setUseEffectState] = useState(1);
  const [signInShow, setSignInShow] = useState(false);
  const [stateObject, setStateObject] = useState(0);
  const userItems = useRef(['sign-in']);
  const runMyCollectionUseEffect = useRef(1);
  const shouldSetFocus = useRef(false);
  const showMyItems = useRef(false);
  const localStorageSetImage = useCallback((item) => {
        if(item.meta.content.length == 2 && item.meta.content[1]["@type"] == "VIDEO"){
        localStorage.setItem("fullScreenImgType", "VIDEO");
        localStorage.setItem("fullScreenImg", item.meta.content[1].url);
    }else if(item.meta.content.length == 2 && item.meta.content[1]["@type"] == "AUDIO") {
        localStorage.setItem("fullScreenImgType", "AUDIO");
        localStorage.setItem("fullScreenImg", item.meta.content[1].url);
    } else if(item.meta.content.length == 1 && item.meta.content[0]["@type"] == "IMAGE"){
        localStorage.setItem("fullScreenImgType", "IMAGE");
        localStorage.setItem("fullScreenImg", item.meta.content[0].url);
    }else if(item.meta.content.length == 1 && item.meta.content[0]["@type"] == "VIDEO"){
        localStorage.setItem("fullScreenImgType", "VIDEO");
        localStorage.setItem("fullScreenImg", item.meta.content[0].url);
    }
  },[])
  const openSignIn = useCallback(() => {
    setSignInShow(true);
  },[]);
  const setRunMyCollectionUseEffect = () => {
    runMyCollectionUseEffect.current = runMyCollectionUseEffect.current + 1;
  };
  const runSetFocusUseEffect = () => {
    shouldSetFocus.current = true;
  }
  useEffect(()=>{
    if(shouldSetFocus.current) {
      shouldSetFocus.current = false;
      focusSelf();
    }
  })
  useEffect(() => {
    if (String(localStorage.getItem("metaMaskAccount")) != "null") {
      (async function () {
        try {
          let res = await getUserItems({});
          userItems.current = res.filter(filterMyCollection);
                    let appendingArray = ['sign-in'];
          userItems.current.length < 4 ? appendingArray = appendingArray : appendingArray = ['sign-in','play-all'];
          userItems.current = appendingArray.concat(userItems.current);
          showMyItems.current = true;
          setUseEffectState(useEffectState + 1);
        } catch (err) {
          console.log("err", err);
          if(err.code == "ERR_NETWORK"){
              setStateObject("Network lost");
          }else{
              setStateObject("Server Error");
          }
        }
      })();
    } else {
      showMyItems.current = false;
      userItems.current = ['sign-in'];
      setUseEffectState(useEffectState + 1);
    }
  }, [runMyCollectionUseEffect.current]);

  const [windowState,setWindowState] = useState(0);
  let cardsCount = [0,1,2,3,4,5,6,7,8,9];
    //check the no of items present in userItems variable
    let length = userItems.current.length;
    if(length < 10) { //make the cardsCount array size = length
        for(let i=1; i<= 10-length; i++) {
            cardsCount.pop();
        }
    }
    if(stateObject == "Server Error") {
      return(<FocusContext.Provider value={focusKey}>
          <Container style={{"textAlign":"center","marginTop":"20%","color":"wheat","fontSize":"x-large"}}>
  
  <span>{translatedStrings?.MAIN_MSG_NFT_SERVER_ERROR}</span>
  
  </Container> 
          </FocusContext.Provider>)
  }
  else if(stateObject == "Network lost"){
      return (
          <FocusContext.Provider value={focusKey}>
          <Container style={{"textAlign":"center","marginTop":"9%","color":"wheat","fontSize":"x-large"}}>

          <span>{translatedStrings?.MAIN_MSG_NFT_NO_INTERNET}</span>

          </Container>
          </FocusContext.Provider>
      );
  }else
  {
  return (
    <FocusContext.Provider value={focusKey}>
      <Row className="mt-xxl-3 margin-top-540p"></Row>
      <Row className="mt-xxl-5 mt-xl-5 mt-sm-4">
        <Col className="mycollections-title">
        {translatedStrings?.MAIN_NFT_MY_LIBRARY} {showMyItems.current ? "| Rarible" : ""}
        </Col>
      </Row>
      <Row className="mt-xxl-2 mt-xl-1 mt-sm-0">
        <div
        ref={ref}
         id="MC-wrapper"
                style={{
                  width: "98vw",
                  overflowX: "scroll",
                  display: "flex",
                  alignItems: "center",
                  height: "19vh",
                }}>

            <>
                {cardsCount.map((index) => {
                    return (
                      <MyCollectionDiv
                       rowRef={ref}
                       localStorageSetImage= {localStorageSetImage}
                       cardsCountIndex = {index}
                       items={userItems.current}
                       item={userItems.current[windowState + index]}
                       windowState = {windowState} 
                       setWindowState = {setWindowState}
                       openSignIn={openSignIn}/>
                    );
                })}
            </>

        </div>
      </Row>
      {signInShow && (
        <SignIn
          show={signInShow}
          onHide={() => {
            setSignInShow(false);
          }}
          runParentUseEffect={() => {
            setRunMyCollectionUseEffect();
          }}
          runSetFocusUseEffect = {()=>{
            runSetFocusUseEffect();
          }}
        />
      )}
    </FocusContext.Provider>
  );
        }
}


function MyCollectionDiv(props) {
  let selectedLanguage = localStorage.getItem("selected-language");
  if(selectedLanguage == null || selectedLanguage == 'null') {
    selectedLanguage = 'en';
  }
  const translatedStrings = translations[selectedLanguage];
  const navigate = useNavigate();
  const { ref, focused } = useFocusable({
    onEnterPress: () => {
      if(props.item == 'sign-in') {
        props.openSignIn();
      }
      else if(props.item == 'play-all') {
        navigate('/slideShow')
      }
      else {
        props.localStorageSetImage(props.item)
        navigate('/nftFullscreen');
      }},
    onArrowPress: (event) => {
      if(event == "right" && props.cardsCountIndex == 9) {//if focus is on last card of cardsCount array
        if((props.items.length - 1)==(props.windowState + props.cardsCountIndex)) {
          //do nothing since last item is displayed by the last card.
        }
        else {
          props.setWindowState(props.windowState + 1);
        }
      }
      if(event == "left" && props.cardsCountIndex == 0) {//if focus is on first card of cardsCount array.
        if((props.windowState + props.cardsCountIndex) == 0) {//(props.windowState + props.cardsCountIndex) = index of the item the first card is displaying.
          //do nothing since first item is displayed by the first card.
        } 
        else {
          props.setWindowState(props.windowState - 1);
        }
      }
    },
    onFocus: () => {
      //logic to align the collections properly in the viewport.
      let cardLeftDistance =
        props.rowRef.current.childNodes[props.cardsCountIndex].getBoundingClientRect().left;
      //horizontal movement
      if (
        cardLeftDistance > (50 / 100) * window.innerWidth ||
        cardLeftDistance < (30 / 100) * window.innerWidth
      ) {
        props.rowRef.current.childNodes[props.cardsCountIndex].scrollIntoView({
          inline: "center",
        });
      }
    }
    });
    if(props.item == 'sign-in') {
      return (
        <div
        ref={ref}
        id="my-collection-default-item-1"
        className={"my-library-focusable custom-center parent-div "+(focused ? "signIn-card-focus":"signIn-card")}
        style={{ flexDirection: "column" }}
      >
        <div className={focused ? "signIn-bImg" : "signIn-wImg"} />
        <div className={focused ? "signIn-bText" : "signIn-wText"}>
          <p className={String(localStorage.getItem("metaMaskAccount")) != "null"?"text-div":""}>{String(localStorage.getItem("metaMaskAccount")) != "null"
            ? String(localStorage.getItem("metaMaskAccount"))
            : <>
            {translatedStrings?.MAIN_NFT_SIGN_IN}
            </>}</p>
        </div>
      </div>
      )
    }
    if(props.item == 'play-all') {
      return(
        <div
    ref={ref}
      id="play-all"
      className={"my-library-focusable custom-center "+(focused?"play-all-disconnect-card-focus":"play-all-disconnect-card")}
      style={{ flexDirection: "column" }}
    >
      <div className={focused ? "playAll-bImg" : "playAll-wImg"} />
      <div className={focused ? "signIn-bText" : "signIn-wText"}>
      {translatedStrings?.MAIN_NFT_PLAY_ALL}
      </div>
    </div>
      )
    }
if(process.env.REACT_APP_SERVER_TYPE == "prodLinux") {
  return (
    <div
    key = {props.item.meta.content[0].url}
    style={{ backgroundImage: `url(${props.item.meta.content[0]["@type"] == "IMAGE" ? (
       props.item.meta.content[0].url 
     ) : (props.item.meta.content[0]["@type"] == "VIDEO" ? (videoPlaceHolder) : "" )})`, backgroundSize:"cover", backgroundPosition: "center" }}
    ref={ref}
                        className={"my-library-focusable custom-center " + (focused?"my-nft-card-focus":"my-nft-card")}
                      >
                      </div>
  )
}
if(process.env.REACT_APP_SERVER_TYPE == "prodAndroid" || process.env.REACT_APP_SERVER_TYPE == "accAndroid") {
  return (
  <div
  ref={ref}
                      className={"my-library-focusable custom-center " + (focused?"my-nft-card-focus":"my-nft-card")}
                    >
                      {props.item.meta.content[0]["@type"] == "IMAGE" ? (
                       <MyCollectionImage item={
                        props.item.meta.content[0].url 
                     } key = {props.item.meta.content[0].url}/>
                      ) : (props.item.meta.content[0]["@type"] == "VIDEO" ? (
                        <MyCollectionImage item={videoPlaceHolder} />
                      ) : "" )
                    }
                    </div>
)
}
else {
  return (
    <div
    key = {props.item.meta.content[0].url}
    style={{ backgroundImage: `url(${props.item.meta.content[0]["@type"] == "IMAGE" ? (
       props.item.meta.content[0].url 
     ) : (props.item.meta.content[0]["@type"] == "VIDEO" ? (videoPlaceHolder) : "" )})`, backgroundSize:"cover", backgroundPosition: "center" }}
    ref={ref}
                        className={"my-library-focusable custom-center " + (focused?"my-nft-card-focus":"my-nft-card")}
                      >
                      </div>
  )
}
}










//For Android TV
// return (
//   <div
//   ref={ref}
//                       className={"my-library-focusable custom-center " + (focused?"my-nft-card-focus":"my-nft-card")}
//                     >
//                       {props.item.meta.content[0]["@type"] == "IMAGE" ? (
//                        <MyCollectionImage item={
//                         props.item.meta.content[0].url 
//                      } key = {props.item.meta.content[0].url}/>
//                       ) : (props.item.meta.content[0]["@type"] == "VIDEO" ? (
//                         <MyCollectionImage item={videoPlaceHolder} />
//                       ) : "" )
//                     }
//                     </div>
// )


//For Linux TV
// return (
//   <div
//   key = {props.item.meta.content[0].url}
//   style={{ backgroundImage: `url(${props.item.meta.content[0]["@type"] == "IMAGE" ? (
//      props.item.meta.content[0].url 
//    ) : (props.item.meta.content[0]["@type"] == "VIDEO" ? (videoPlaceHolder) : "" )})`, backgroundSize:"cover", backgroundPosition: "center" }}
//   ref={ref}
//                       className={"my-library-focusable custom-center " + (focused?"my-nft-card-focus":"my-nft-card")}
//                     >
//                     </div>
// )

