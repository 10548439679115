import axios from 'axios';
const auth = axios.create({ baseURL: 'http://localhost:8000/api/v1' });
const api = axios.create({ baseURL: 'http://localhost:8000/api/v1' });
api.interceptors.request.use(
    (request) => {
       const token = localStorage.getItem('RFUtoken')
       const isLoggedIn = localStorage.getItem('RFUloggedIn');
       if(isLoggedIn === "true") {
         request.headers.Authorization = `Bearer ${token}`;
       }
       return request;
    },
    (err) => {
       return Promise.reject(err);
    }
 );
 export {auth,api};